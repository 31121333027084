import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";

import { AiOutlineDownload } from "react-icons/ai";

function ResumeNew() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section">
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            border: "1px solid #ccc",
          }}
          className="pdf-pages-container"
        >
          <div style={{ padding: 60 }}>
            <h1 style={{ color: "#03a9f4", fontWeight: "bold" }}>
              DOORPAL APP. TERMS OF SERVICE
            </h1>

            <p style={{ color: "#000", textAlign: "start" }}>
              EFFECTIVE: June 2024
            </p>

            <p style={{ color: "#000", textAlign: "start" }}>
              These terms of service constitute a legally binding agreement (the
              “Agreement”) between you and the DoorPal application and
              technology platform and all its affiliations (the “DoorPal app.”).
            </p>

            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Definitions.&nbsp;</strong>For purposes of these Terms of
              Service or Service Description, the following definitions will
              apply:
            </p>
            <ul style={{ color: "#000", textAlign: "start" }}>
              <li>
                <strong>&ldquo;DoorPal app.&rdquo;</strong> means the DoorPal
                application, technology platform and all its affiliations.
              </li>
              <li>
                <strong>&ldquo;Door&rdquo;</strong> means&nbsp;door, place,
                person, point, location of interest, an immaterial abstracts,
                intention, or situation (e.g., fire, event, sale, or emergency),
                or material objects (e.g., luggage, car, or passport) a DoorPal
                app. platform mediated notification, communication or messaging
                pathway&nbsp;
              </li>
              <li>
                <strong>&ldquo;The agreement&rdquo; or&nbsp;</strong>
                <strong>&ldquo;DoorPal app Documentation&rdquo;</strong>  means
                this DoorPal app Terms of Service, Services Description, and any
                additional description of the Services which may be incorporated
                into this Agreement, DoorPal app., or platform, in whole it
                represents the legally binding terms of service between you and
                DoorPal app.
              </li>
              <li>
                <strong>&ldquo;Hardware Programs&rdquo;</strong>  mean services
                or programs provided by DoorPal app that enable users to procure
                hardware devices or use hardware functions and network resources
                (e.g., from your or others smart devices) to permit the
                exchanges (e.g., notification, message, sound, voice, picture,
                video, location, descriptions, details, QR code, others) that
                DoorPal app. requires to perform its function. These Services
                are subject to separate terms with the equipment manufacturers.
              </li>
              <li>
                <strong>&ldquo;Host&rdquo;</strong> means any user or users
                (e.g., individual, individuals, agency, business, group) who
                sign up for the DoorPal app. and use it to assign a door, place,
                person, point, location of interest, an immaterial abstract or
                situation (e.g. fire or emergency or love), or material thing
                (e.g. luggage or passport) a platform mediated notification or
                messaging pathway or any user who get invited and accept to have
                one or more of these assigned creations added to their account
                as hosts or co-hosts. It might be but not limited to an
                identified self, place (e.g., home) owner, place (e.g., home)
                inhabitant (transient, long term or permanent), employee,
                contractor, family member, friend, or agent of a user to whom a
                user assigns the right to host or manage DoorPal app mediated
                exchanges.  A Host may assign or pin one location as a
                designated door or point of interest while on the basic sign-up
                Term or Renewal Term (as applicable), but the number of assigned
                or pin locations can exceed one if the host or Customer orders a
                premium subscription. A Host subscription may not be shared or
                used by anyone other than the individual assigned to be a Host.
              </li>
              <li>
                <strong>&ldquo;Users&rdquo;&nbsp;</strong>means anyone who uses
                the DoorPal app platform, including hosts, end users and
                participants.
              </li>
              <li>
                <strong>&ldquo;End users&rdquo;&nbsp;</strong>means any user who
                is given some or all host privileges but has not initiated the
                door, place, location, or point of interest assignment,
                selection, modification, editing, or designation.
              </li>
              <li>
                <strong>&ldquo;Exchange&rdquo;</strong> means a DoorPal app
                sending and receiving of (notification, location, video, sound,
                voice, messages, pictures, descriptions, data, information, QR
                codes, listing, map posting, and others). &nbsp;
              </li>
              <li>
                <strong>&ldquo;DoorPal app exchange Services&rdquo;</strong>{" "}
                means the various sending and receiving of video, web
                conferencing, notifications, data, information, alerts, detail
                open listing for search purposes, open map posting,
                descriptions, screensharing, chat, connectors, audio steams or
                recordings, voice connectivity services, including, but not
                limited to, interconnected VoIP services, provisioning of direct
                dial numbers, two-way voice calling and private branch exchange
                (PBX) functionality and related services offered (or might be
                later offered) and other collaborative services offered by the
                DoorPal app that a Customer may order on an Order Form.
              </li>
              <li>
                <strong>&ldquo;Participant&rdquo;</strong> typically means an
                individual, other than the Host or Hosts (who assigns or is
                assigned a door, location, place, post, listing or point of
                interest on the DoorPal app), who accesses or uses the Services,
                with or without the permission and knowledge of the Host or
                Hosts. In certain situations, the participant can be a host if
                the host is using the DoorPal app. to gain the attention of
                another host or co-host.
              </li>
              <li>
                <strong>&ldquo;Charges&rdquo;&nbsp;</strong>any fees, fares,
                rates, financial obligations, or amounts billed to you by our
                non-free plans for providing Services to you.
              </li>
            </ul>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <ul style={{ color: "#000", textAlign: "start" }}>
              <li>
                &ldquo;<strong>Affiliate</strong>&rdquo; means, with respect to
                a Party, any entity that directly or indirectly controls, is
                controlled by or is under common control with that Party. For
                purposes of this Agreement, “control” means an economic or
                voting interest of at least fifty percent (50%) or, in the
                absence of such economic or voting interest, the power to direct
                or cause the direction of the management and set the policies of
                such entity.
              </li>
            </ul>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Description:&nbsp;</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              DoorPal app. enable Hosts to have a smart device alternative (or a
              backup) to physical doorbells, various other notification systems,
              classifieds, tags, advertisement, bells in general, or the like of
              alerting inventions that can be used to reach, inform, or alert
              (and may also open one or more channel of communication (e.g.,
              text, sound, voice, picture, video, chat, &hellip; others) a Host,
              co-host, participant, employee, business owner, manager, merchant,
              organizer, resident or inhabitant of an assigned, tagged, pined
              place (eg door, home, private property, public property, business,
              thing, or activity organizer) of a visitor, participant, non-host
              user, customer or the like of individuals who seek attention of
              the host (or hosts) and to allow participants to gain the
              attention of the host (or hosts)&nbsp;&nbsp;thru a smart device
              alternative to a physical doorbell, various other notification
              systems, classifieds, tags, advertisement, bells in general, or
              the like (and may also open one or more channel of communication
              (e.g., text, sound, voice, picture, video, chat, &hellip; others
              )). Every assigned door, listing, or pin location will have at
              least one Host. &nbsp;Chat, Video, Voice, Sound, Location and
              place details and description features allow one-on-one or group
              collaboration exclusively or generally, as well as momentarily or
              more long-lasting thru trigger gateways such as arriving at a
              designated location, receiving of link, using shared instructions,
              invitation, a searchable listing or map posting.&nbsp;&nbsp;The
              Concurrent feature enables a Host to host more than one assigned
              door, listing, place, point, or pin location subject to the
              specific limitations of the subscription type (basic free
              subscription are only allowed one assigned door, place, listing,
              point or pin location of interest). DoorPal app Services might
              include two-way voice calling, private branch exchange (PBX)
              functionality, and cloud-based phone services that might use voice
              over internet protocol (<strong>VoIP</strong>) to execute some of
              its tasks and functionalities. All users that download and agree
              to use the DoorPal app. (including hosts and participants) should
              understand and agree to permit the DoorPal app permission for the
              use of their smart devices functions and network resources (which
              might be &ldquo;Metered&rdquo; or &ldquo;Unlimited&rdquo;) to
              execute its tasks and the users (hosts and participants) absorb
              full responsibility of any costs that may result in the use of
              these smart device functions or network resources by the DoorPal
              app.. &nbsp;Furthermore, DoorPal app. is the provider of DoorPal
              app Services and sets the terms, conditions and rates for DoorPal
              app Services that might be subject to change, revision, and
              updates.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              The DoorPal app. will provide the Services, and you may access and
              use the Services, in accordance with this Agreement. DoorPal app.
              may provide any of the Services hereunder through any of its
              Affiliates. If You order Services through an on-line registration
              page or an order form (each an &ldquo;Order Form&rdquo;), the
              Order Form may contain additional terms and conditions and
              information regarding the Services you are ordering. Unless
              otherwise expressly set forth in any such additional terms and
              conditions applicable to the specific Service which you choose to
              use, those additional terms are hereby incorporated into this
              Agreement in relation to your use of that Service.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              DoorPal app will provide the Services as described on the Order
              Form, and standard updates to the Services that are made generally
              available by DoorPal app during the term. DoorPal app may, in its
              sole discretion, discontinue the Services or modify the features
              of the Services from time to time without prior notice. We aim to
              update the DoorPal application Platform regularly and may change
              the content at any time. If the need arises, we may suspend access
              to the DoorPal application Platform and the Services or close them
              indefinitely. Any of the material on the DoorPal application
              Platform or the Services may be out of date at any given time, and
              we are under no obligation to update such material.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <ul style={{ color: "#000", textAlign: "start" }}>
              <ul style={{ color: "#000", textAlign: "start" }}>
                <li>
                  <strong>Beta Services.</strong> DoorPal app on initial launch
                  and beyond for an unspecified time after the initial launch
                  will go thru a Beta Service phase, and after the initial Beta
                  Service phase may, from time to time, offer access to services
                  that are classified as Beta version. Access to and use of Beta
                  versions may be subject to additional agreements. DoorPal app
                  makes no representations that a Beta version will ever be made
                  generally available and reserves the right to discontinue or
                  modify a Beta version at any time without notice. Beta
                  versions are provided AS IS, may contain bugs, errors or other
                  defects, and Your use of a Beta version is at Your sole risk.
                </li>
              </ul>
            </ul>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <p>
                <strong>
                  IMPORTANT, READ CAREFULLY : YOUR USE OF AND ACCESS TO THE
                  PRODUCTS AND SERVICES AND ASSOCIATED SOFTWARE (COLLECTIVELY,
                  THE &ldquo;SERVICES&rdquo;) OF THE DOORPAL APP. AND ITS
                  AFFILIATES IS CONDITIONED UPON YOUR COMPLIANCE WITH AND
                  ACCEPTANCE OF THESE TERMS, WHICH INCLUDE YOUR AGREEMENT TO
                  ARBITRATE CLAIMS. PLEASE REVIEW THOROUGHLY BEFORE
                  ACCEPTING.&nbsp;
                </strong>
                <strong>
                  IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF
                  THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE DOORPAL
                  APPLICATION AND TECHNOLOGY PLATFORM.
                </strong>
              </p>
              <p>
                <strong>
                  BY CLICKING/CHECKING THE &ldquo;I AGREE&rdquo; BUTTON/BOX,
                  ACCESSING THE DOORPAL APP. OR BY UTILIZING THE DOORPAL APP.
                  SERVICES YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE AND
                  ALL EXHIBITS, ORDER FORMS, AND INCORPORATED POLICIES (THE
                  &ldquo;AGREEMENT&rdquo; &ldquo;TERMS OF SERVICE&rdquo; OR
                  &ldquo;TOS&rdquo;). THE DOORPAL APP. SERVICES ARE NOT
                  AVAILABLE TO PERSONS WHO ARE NOT LEGALLY ELIGIBLE TO BE BOUND
                  BY THESE TERMS OF SERVICE.&nbsp;
                </strong>
              </p>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>
                BY CLICKING/CHECKING THE &ldquo;I AGREE&rdquo; BUTTON/BOX,
                ACCESSING THE DoorPal APP. OR BY UTILIZING THE DoorPal APP.
                SERVICES YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE AND ALL
                EXHIBITS, ORDER FORMS, AND INCORPORATED POLICIES (THE
                &ldquo;AGREEMENT&rdquo; &ldquo;TERMS OF SERVICE&rdquo; OR
                &ldquo;TOS&rdquo;). THE DoorPal APP. SERVICES ARE NOT AVAILABLE
                TO PERSONS WHO ARE NOT LEGALLY ELIGIBLE TO BE BOUND BY THESE
                TERMS OF SERVICE.&nbsp;
              </strong>
            </p>

            <p style={{ color: "#000", textAlign: "start" }}>
              DoorPal application and technology platform may immediately
              terminate this Agreement with respect to you, or generally cease
              offering or deny access to the DoorPal application and Platform or
              any portion thereof, at any time for any reason without notice or
              refund.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              Supplemental terms may apply to certain Services (as defined
              below), such as policies for a particular event, loyalty program,
              activity or promotion, and such supplemental terms will be
              disclosed to you in connection with the applicable Services.
              Supplemental terms are in addition to, and shall be deemed a part
              of, this Agreement for the purposes of the applicable Services.
              Supplemental terms shall prevail over this Agreement in the event
              of a conflict with respect to the applicable Services.
            </p>

            <p style={{ color: "#000", textAlign: "start" }}>
              DoorPal may provide to a claims processor or an insurer any
              necessary information (including your contact information) if
              there is a complaint, dispute or conflict, which may include an
              incident that requires government authority, court, or law
              enforcement involvement, involving you and a third party provider
              and such information or data, where it might be considered
              valuable to resolve the complaint, dispute or conflict.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              Materials posted on the DoorPal application Platform are not
              intended to amount to advice on which reliance should be placed.
              We therefore disclaim all liability and responsibility arising
              from any reliance placed on such materials by any user or visitor
              to the DoorPal application Platform, or by anyone who may be
              informed of any of its contents.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>
                YOU ACKNOWLEDGE THAT NEITHER DOORPAL NOR ITS AFFILIATES OWN OR
                MANAGE OR INHABIT THE POSTED PLACES, LOCATIONS OR ACTIVITIES AND
                THAT ALL THESE THINGS ARE PROVIDED BY INDEPENDENT THIRD PARTY
                HOSTS, USERS, CUSTOMERS, OR PARTICIPANTS WHO ARE NOT EMPLOYED BY
                DOORPAL APP OR ANY OF ITS AFFILIATES.
              </strong>
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>System Requirements</strong>.Use of the DoorPal app.
              Services requires one or more compatible devices, Internet access
              (fees may apply), and certain software (fees may apply), and may
              require obtaining updates or upgrades from time to time. Because
              use of the Services involves hardware, software, and Internet
              access, your ability to access and use the Services may be
              affected by the performance of these factors. High speed Internet
              access is recommended. You acknowledge and agree that such system
              requirements, which may be changed from time to time, are Your
              responsibility.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Government and Authority&nbsp;Requirements</strong>. Use
              of the DoorPal app. Services might be subjected to limitations,
              interruptions, or complete blockade by regulators from time to
              time or permanently. Therefore, your ability to access and use the
              Services may be affected by these factors. You acknowledge and
              agree that such requirements, which may be changed from time to
              time, are your responsibility and agree to not hold DoorPal app.
              Services accountable for such potential sources of denial or
              limitation of service.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Equipment</strong>. &nbsp;DoorPal app services does not
              supply any Devices or other equipment used in connection with the
              DoorPal app Services, and accordingly DoorPal app services does
              not provide any guarantees as to the quality or operability of
              such Devices and equipment when used to access or execute DoorPal
              app Services.  However, DoorPal app does test certain Devices and
              equipment to determine whether such Devices and equipment are
              supported on the DoorPal app platform (although it has not tested
              all possible Devices and equipment available in the marketplace). 
              &nbsp;
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>License.</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              Subject to your compliance with this Agreement, DoorPal app.
              grants you a limited, non-exclusive, non-sub-licensable,
              revocable, non-transferrable license to access and use the DoorPal
              Platform on your personal device solely in connection with your
              use of the DoorPal Platform solely for your personal,
              non-commercial use (commercial use is permitted if you sign up
              with the premium service). Any rights not expressly granted herein
              are reserved by DoorPal
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>USE OF SERVICES AND YOUR RESPONSIBILITIES.&nbsp;</strong>
              You may only use the Services pursuant to the terms of this
              Agreement. You are solely responsible for Your and Your End Users’
              use of the Services and shall abide by, and ensure compliance
              with, all Laws in connection with Your and each End User’s use of
              the Services, including but not limited to Laws related to
              recording, intellectual property, privacy, and export control. Use
              of the Services is void where prohibited.
            </p>
            <ul style={{ color: "#000", textAlign: "start" }}>
              <ul style={{ color: "#000", textAlign: "start" }}>
                <li>
                  <strong>Registration Information</strong>.&nbsp;You may be
                  required to provide information about Yourself in order to
                  register for and/or use certain Services. You agree that any
                  such information shall be accurate. You may also be asked to
                  choose a username and password. You are entirely responsible
                  for maintaining the security of Your username and password and
                  agree not to disclose such to any third party.
                </li>
                <li>
                  <strong>Your Content</strong>.&nbsp; You agree that You are
                  solely responsible for the content (“Content”) sent or
                  transmitted by You or displayed or uploaded by You in using
                  the Services and for compliance with all Laws pertaining to
                  the Content, including, but not limited to, Laws requiring You
                  to obtain the consent of a third party to use the Content and
                  to provide appropriate notices of third-party rights. You
                  represent and warrant that You have the right to upload the
                  Content to DoorPal app and that such use does not violate or
                  infringe on any rights of any third party. Under no
                  circumstances will DoorPal app be liable in any way for any
                  (a) Content that is transmitted or viewed while using the
                  Services, (b) errors or omissions in the Content, or (c) any
                  loss or damage of any kind incurred as a result of the use of,
                  access to, or denial of access to Content. Although DoorPal
                  app is not responsible for any Content, DoorPal app may delete
                  any Content, at any time without notice to You, if DoorPal app
                  becomes aware that it violates any provision of this
                  Agreement, or any law. You retain copyright and any other
                  rights You already hold in Content which You submit, post or
                  display on or through, the Services.
                </li>
                <li>
                  <strong>Recordings</strong>.You are responsible for compliance
                  with all recording laws. The host can choose to record DoorPal
                  app interactions and exchanges. By using the Services, you are
                  giving DoorPal app consent to store recordings for any or all
                  DoorPal app activities that you join, if such recordings are
                  stored in our systems. If you do not consent to being
                  recorded, you can choose to not start, leave the interaction
                  or exchange.
                </li>
                <li>
                  <strong>Prohibited Use.</strong>You agree and must comply with
                  all the applicable laws, our terms of service, and all the
                  rules, and You will not use, and will not permit any End User
                  to use, the Services otherwise. Here is a partial list of
                  goods, services, and content that are prohibited on DoorPal
                  App: (i) modify, disassemble, decompile, prepare derivative
                  works of, reverse engineer or otherwise attempt to gain access
                  to the source code of the Services; (ii) knowingly or
                  negligently use the Services in a way that abuses, interferes
                  with, or disrupts DoorPal app’s networks, user smart devices,
                  network resources, Your accounts, or the Services; (iii)
                  engage in activity that is illegal, fraudulent, false, or
                  misleading, (iv) transmit through the Services any material
                  that may infringe the intellectual property or other rights of
                  third parties; (v) build or benchmark a competitive product or
                  service, or copy any features, content, functions or graphics
                  of the Services; You also agree not to use or provide software
                  (except ours) or services that interact or interoperate with
                  ours, e.g. for downloading, uploading,
                  creating/accessing/using an account, posting, flagging,
                  emailing, searching, or mobile use. You agree not to
                  copy/collect our content via robots, spiders, scripts,
                  scrapers, crawlers, or any automated or manual equivalent
                  (e.g., by hand), or (vi) use the Services to communicate any
                  message or material that is harassing, libelous, hateful,
                  threatening, obscene, indecent, would violate the intellectual
                  property rights of any party or is otherwise unlawful, that
                  would give rise to civil liability, or that constitutes or
                  encourages conduct that could constitute a criminal offense,
                  under any applicable law or regulation; (vii) upload or
                  transmit any software, Content or code that does or is
                  intended to harm, disable, destroy or adversely affect
                  performance of the Services in any way or which does or is
                  intended to harm or extract information or data from other
                  hardware, software or networks of DoorPal app or other users
                  of Services; (viii) engage in any activity or use the Services
                  in any manner that could damage, disable, overburden, impair
                  or otherwise interfere with or disrupt the Services, or any
                  servers or networks connected to the Services or DoorPal app’s
                  security systems. (ix) use the Services in violation of any
                  DoorPal app policy or in a manner that violates applicable
                  law, including but not limited to anti-spam, export control,
                  privacy, and anti-terrorism laws and regulations and laws
                  requiring the consent of subjects of audio and video
                  recordings, and You agree that You are solely responsible for
                  compliance with all such laws and regulations. (x) impersonate
                  any person or entity. (xi) stalk, threaten, or otherwise
                  harass any person (including other Users making simultaneous
                  use of the Service), or carry any weapons (xii) violate any
                  law, statute, rule, permit, ordinance or regulation (xiii)
                  interfere with or disrupt the Services or the DoorPal app
                  Platform or the servers or networks connected to the DoorPal
                  app Platform (xiv) post information or interact on the DoorPal
                  app Platform or with respect to Services in a manner which is
                  false, inaccurate, misleading (directly or by omission or
                  failure to update information), defamatory, libelous, hateful,
                  abusive, obscene, profane, offensive, sexually oriented,
                  threatening, harassing, or illegal (xv) use the DoorPal app
                  Platform in any way that infringes any third party’s rights,
                  including but not limited to: intellectual property rights,
                  copyright, patent, trademark, trade secret or other
                  proprietary rights or rights of publicity or privacy (xvi)
                  post, email or otherwise transmit any malicious code, files or
                  programs designed to interrupt, damage, destroy or limit the
                  functionality of any computer software or hardware or
                  telecommunications equipment or surreptitiously intercept or
                  expropriate any system, data or personal information (xvii)
                  forge headers or otherwise manipulate identifiers in order to
                  disguise the origin of any information transmitted through the
                  DoorPal app Platform (xviii) “frame” or “mirror” any part of
                  the DoorPal app Platform, without our prior written
                  authorization or use meta tags or code or other devices
                  containing any reference to us in order to direct any person
                  to any other web site for any purpose (xix) modify, adapt,
                  translate, reverse engineer, decipher, decompile or otherwise
                  disassemble any portion of the DoorPal app Platform or any
                  software used on or for the DoorPal app Platform (xx) rent,
                  lease, lend, sell, redistribute, license or sublicense the
                  DoorPal app Platform or access to any portion of the DoorPal
                  app Platform (xxi) use any robot, spider, site
                  search/retrieval application, or other manual or automatic
                  device or process to retrieve, index, scrape, “data mine”, or
                  in any way reproduce or circumvent the navigational structure
                  or presentation of the DoorPal app Platform or its contents
                  (xxii) link directly or indirectly to any other web sites
                  (xxiii) transfer or sell your User account, password and/or
                  identification to any other party (xxiv) discriminate against
                  or harass anyone generally, for example discrimination or
                  harassment on the basis of race, national origin, religion,
                  gender, physical or mental disability, medical condition,
                  marital status, or age (xxv) cause any third party to engage
                  in the restricted activities above. (xxvi) activity related to
                  sale of weapons; firearms/guns and components; BB/pellet,
                  stun, and spear guns; ammunition, clips, cartridges, reloading
                  materials, gunpowder, fireworks, explosives, etc. (xxvii)
                  activity related to offers, solicitation, or facilitation of
                  illegal prostitution and/or sex trafficking exploitation or
                  endangerment of minors; child pornography. (xxviii) Sale of
                  recalled items, hazardous materials; body parts/fluids;
                  unsanitized bedding/clothing, prescription drugs, medical
                  devices; controlled substances and related items, alcohol or
                  tobacco; unpackaged or adulterated food or cosmetics,
                  endangered, imperiled and/or protected species and any parts
                  thereof, (e.g. ivory), food stamps, WIC vouchers, SNAP or WIC
                  goods, governmental assistance stolen property, property with
                  serial number removed/altered, burglary tools, ID cards,
                  licenses, police insignia, government documents, birth
                  certificates, military items not demilitarized in accord with
                  applicable Defense Department policy, counterfeit, replica, or
                  pirated items; tickets or gift cards that restrict transfer
                  lottery or raffle tickets, sweepstakes entries, slot machines,
                  gambling items.
                </li>
              </ul>
            </ul>
            <p style={{ color: "#000", textAlign: "start" }}>
              In the event that you undertake in any of the above while
              participating in Services, DoorPal app shall be permitted to
              refuse to provide you Services or, if such Services have
              commenced, DoorPal app is permitted to refuse to continue to
              provide you Services.
            </p>
            <ul style={{ color: "#000", textAlign: "start" }}>
              <ul style={{ color: "#000", textAlign: "start" }}>
                <ul style={{ color: "#000", textAlign: "start" }}>
                  <li>
                    By agreeing to the terms of service all users agree to not
                    hold DoorPal App accountable for any scam that they may fall
                    victim to, and they understand it is the user’s sole
                    responsibility to be careful and on the lookout for
                    potential scams. The users are encouraged to follow safe
                    practices but acknowledge that these actions may help avoid
                    but cannot completely prevent scams, such common knowledge
                    safe practice recommendations include:
                    <ul style={{ color: "#000", textAlign: "start" }}>
                      <li>Deal locally, face-to-face.</li>
                      <li>
                        Do not&nbsp;provide payment to anyone you have not met
                        in person.
                      </li>
                      <li>
                        Beware offers involving shipping -&nbsp;deal with locals
                        you can meet in person.
                      </li>
                      <li>
                        Never wire funds (e.g. Western Union)&nbsp;- anyone who
                        asks you to is a potential scammer.
                      </li>
                      <li>
                        Don&apos;t accept cashier/certified checks or money
                        orders&nbsp;- banks cash fakes, then hold you
                        responsible.
                      </li>
                      <li>
                        With respect to transactions, no third party provides a
                        &quot;guarantee&quot;.
                      </li>
                      <li>
                        Never give out personal or financial info&nbsp;(bank
                        account, social security, paypal account, etc).
                      </li>
                      <li>
                        Do not rent or purchase sight-unseen&mdash;that amazing
                        &quot;deal&quot; may not exist.
                      </li>
                      <li>
                        Refuse background/credit checks&nbsp;until you have met
                        your landlord/employer in person.
                      </li>
                      <li>
                        Don&rsquo;t allow children to answer the door, phone, or
                        bell.
                      </li>
                    </ul>
                  </li>
                  <li>
                    The overwhelming majority of internet and online users are
                    trustworthy and well-meaning. With billions of human
                    interactions, the incidence of violent crime related to
                    online activities&nbsp;is extremely low. By agreeing to the
                    terms of service all users agree to not hold DoorPal App
                    accountable for their personal safety, and they understand
                    it is the user&rsquo;s sole responsibility to be careful and
                    on the lookout for potential harm. The users are encouraged
                    to follow the same safe practices and common-sense
                    precautions online as you would offline, but acknowledge
                    that these actions may help avoid but cannot completely
                    prevent harm, such common knowledge safe practice
                    recommendations include:
                    <ul style={{ color: "#000", textAlign: "start" }}>
                      <li>
                        When meeting someone for the first time, please remember
                        to:
                        <ul style={{ color: "#000", textAlign: "start" }}>
                          <li>
                            Not allow minors to answer the door, phone or bell
                          </li>
                          <li>
                            Insist on a public meeting place like a cafe, bank,
                            or shopping center.
                          </li>
                          <li>
                            Do not meet in a secluded place or invite strangers
                            into your home.
                          </li>
                          <li>
                            Be especially careful buying/selling high value
                            items.
                          </li>
                          <li>
                            Tell a friend or family member where you&apos;re
                            going.
                          </li>
                          <li>Take your cell phone along if you have one.</li>
                          <li>Consider having a friend accompany you.</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </ul>
            </ul>
            <p style={{ color: "#000", textAlign: "start" }}>
              Taking these simple precautions helps make it safer for everyone.
              For more information about personal safety online, check out these
              resources:
            </p>
            <p style={{ color: "#000", textAlign: "center" }}>
              http://www.staysafeonline.org/
            </p>
            <p style={{ color: "#000", textAlign: "center" }}>
              http://www.onguardonline.gov/
            </p>
            <p style={{ color: "#000", textAlign: "center" }}>
              http://getsafeonline.org
            </p>
            <p style={{ color: "#000", textAlign: "center" }}>
              http://wiredsafety.org
            </p>
            {/* <p style={{ color: "#000", textAlign: "center" }}>
              <br />
            </p> */}
            <ul style={{ color: "#000", textAlign: "start" }}>
              <ul style={{ color: "#000", textAlign: "start" }}>
                <li>
                  <strong>Limitations on Use</strong>.&nbsp;You may not
                  reproduce, resell, or distribute the Services or any reports
                  or data generated by the Services for any purpose unless You
                  have been specifically permitted to do so under a separate
                  agreement with DoorPal app. You may not offer or enable any
                  third parties to use the Services purchased by You, display on
                  any website or otherwise publish the Services or any Content
                  obtained from a Service (other than Content created by You) or
                  otherwise generate income from the Services or use the
                  Services for the development, production or marketing of a
                  service or product substantially similar to the Services.
                </li>
                <li>
                  <strong>Alternative uses:</strong> DoorPal app services might
                  be used to execute tasks other than being a physical DoorPal
                  alternative (or back up). With these alternative utilizations,
                  it is the sole responsibility of the users to not put
                  themselves, other users, their devices, or networks in
                  harm&rsquo;s way or to participate, facilitate, or encourage
                  illegal activities.
                </li>
                <li>
                  <strong>RESPONSIBILITY FOR END USERS</strong>. You are
                  responsible for the activities of all End Users who access or
                  use the Services after you have given the some or all host
                  privileges you initiated for a door, place, location, or point
                  of interest assignment, place selection, modification, or
                  designation through your sign-up, subscription, or account,
                  and you agree to ensure that any such End User will comply
                  with the terms of this Agreement and any DoorPal app policies.
                  DoorPal app assumes no responsibility or liability for
                  violations. If You become aware of any violation of this
                  Agreement in connection with use of the Services by any
                  person, please contact DoorPal app at support@doorbelap.com.
                  DoorPal app may investigate any complaints and violations that
                  come to its attention and may take any (or no) action that it
                  believes is appropriate, including, but not limited to issuing
                  warnings, removing the content or terminating accounts and/or
                  User profiles. Under no circumstances will DoorPal app be
                  liable in any way for any data or other content viewed while
                  using the Services, including, but not limited to, any errors
                  or omissions in any such data or content, or any loss or
                  damage of any kind incurred as a result of the use of, access
                  to, or denial of access to any data or content.
                </li>
              </ul>
            </ul>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>DoorPal APP OBLIGATIONS FOR CONTENT</strong>
              .&nbsp;DoorPal app will maintain reasonable physical and technical
              safeguards to prevent unauthorized disclosure of or access to
              Content. DoorPal app will notify You if it becomes aware of
              unauthorized access to Content. DoorPal app will not access, view
              or process Content except (a) as provided for in this Agreement
              and in DoorPal app&rsquo;s Privacy Statement; (b) as authorized or
              instructed by You, (c) as required to perform its obligations
              under this Agreement; or (d) as required by Law. DoorPal app has
              no other obligations with respect to Content.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>ELIGIBILITY</strong>. You affirm that You are at least 18
              years of age and are otherwise fully able and competent to enter
              into the terms, conditions, obligations, affirmations,
              representations, and warranties set forth in this Agreement, and
              to abide by and comply with this Agreement. &nbsp;Your access may
              be terminated without warning if we believe that You are under the
              age of 18 or are otherwise ineligible.
              <strong>&nbsp;RESTRICTION ON USE BY CHILDREN</strong>. DoorPal app
              is not intended for use by individuals under the age of 18.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Third Party Services and Content.</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              The DoorPal app Platform may be made available or accessed in
              connection with third party services and content (including
              advertising). DoorPal app does not endorse such third-party
              services and content and you agree that, in no event, will DoorPal
              app be responsible or liable for any products or services of such
              third-party providers. Additionally, Apple Inc., Google, Inc.,
              and/or their applicable international subsidiaries and affiliates,
              and/or their applicable equivalents/alternatives, will be
              third-party beneficiaries to this contract if you access the
              DoorPal app Platform using applications developed for Apple iOS,
              Android-powered mobile devices, or equivalent/alternatives
              respectively. These third-party beneficiaries are not parties to
              this contract and are not responsible for the provision or support
              of the DoorPal app Platform in any manner.&nbsp;
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              When purchasing goods or services provided by a Merchant Host,
              Participant, or End User, you agree and understand that you are
              doing so subject to the relevant Merchant&rsquo;s terms and
              conditions and at your own risk. You agree that DoorPal will not
              be liable for: (i) any act or omission of a Merchant Host,
              Participant, or End User and/or (ii) any damage or loss that
              arises in connection with the ordering, purchase and/or provision
              of a Merchant Host, Participant, or End User&rsquo;s goods or
              service.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              You further understand that to enable a Merchant Host,
              Participant, or End User to provide you with relevant goods or
              services, DoorPal app may be required to share personal
              information in the manner described in DoorPal apps Privacy
              Notice.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>DoorPal app Platform Ownership.</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              The DoorPal app Platform and all rights therein are and shall
              remain DoorPal app&rsquo;s property. Neither this Agreement nor
              your use of the DoorPal app Platform convey or grant to you any
              rights: (i) in or related to the DoorPal app Platform except for
              the limited license granted above; or (ii) to use or reference in
              any manner DoorPal app&rsquo;s names, logos, product and service
              names, trademarks or services marks or those of DoorPal
              app&rsquo;s licensors.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              Use of the DoorPal app Platform<strong>&nbsp;</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>User Accounts.</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              In order to use most aspects of the DoorPal app Platform, you must
              register for and maintain an active personal User account
              (&ldquo;Account&rdquo;). You must be at least 18 years of age to
              obtain an Account. Account registration requires you to submit to
              DoorPal certain personal information, such as your name, address,
              mobile phone number and age. You agree to maintain accurate,
              complete, and up-to-date information in your Account. Your failure
              to maintain accurate, complete, and up-to-date Account information
              may result in your inability to access and use the DoorPal app
              Platform, including your ability to request access to your
              personal information or to opt in or out of preferences, or
              DoorPal app&rsquo;s termination of this Agreement with you.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              You agree to maintain the security and secrecy of your Account
              username and password at all times. You agree and understand that
              you are responsible for all activity that occurs under your
              Account, even as a result of loss, damage or theft of the device
              through which you access the DoorPal app Platform.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              Unless otherwise permitted by DoorPal app thru its premium
              subscription, you may only possess one Account and assign one
              door, place, home, apartment, business, or location. Linked
              co-hosts you invited to a certain door, place, home, apartment,
              business, or location are not permitted without a premium
              subscription to assign additional doors, places, homes,
              apartments, businesses, or locations on their own independently
              unless they upgrade to the premium subscription.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>User Requirements and Conduct.</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              The DoorPal app Platform is not available for use by persons under
              the age of 18 and may only be used by individuals who can form
              legally binding contracts under applicable law. You may not
              authorize third parties to use your Account, and you may not allow
              persons under the age of 18 to receive services from DoorPal app.
              You may not assign or otherwise transfer your Account to any other
              person or entity. You agree to comply with all applicable laws
              when using the DoorPal app Platform, and you may only use the
              DoorPal app Platform for lawful purposes. You shall not, for the
              avoidance of doubt, use the DoorPal app Platform to deal with,
              facilitate or procure illegal or banned substances or items. You
              will not, in your use of the DoorPal app Platform, cause nuisance,
              annoyance, insult, inconvenience, or property damage, whether to
              yourself, others, private properties, public properties,
              businesses, other third-party users or any other party. In certain
              instances, you may be asked to provide proof of identity to access
              or use the DoorPal app Platform, and you agree that you may be
              denied access to or use of the DoorPal app Platform if you refuse
              to provide proof of identity. Failure to comply with the terms of
              this section or of others sections may result in our taking
              certain actions against you, including but not limited to: (i)
              immediate, temporary or permanent withdrawal of your right to use
              the DoorPal app Platform (ii) legal action against you including
              proceedings for reimbursement of all costs on an (including, but
              not limited to, reasonable administrative and legal costs)
              resulting from the breach (iii) disclosure of such information to
              law enforcement authorities as we feel is necessary and/or (iv)
              immediate, temporary or permanent removal of any posting or
              material uploaded by you to our Service.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>
                DoorPal app premium subscription, business promotion, and
                advertisement
              </strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              As a User, you may be eligible to enroll in the DoorPal app
              premium subscription, business promotion, and advertise, which are
              one or more subscription plans through which you may access
              benefits that you can avail through the DoorPal app Platform.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              The Benefits will either be automatically applied when making a
              transaction through the DoorPal app Platform or you may be
              required to carry out extra steps to make use of a Benefit, which
              steps will be communicated to you by DoorPal app from time to
              time.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              The availability of Benefits is subject to all applicable local
              laws and regulations and are offered in good faith, however they
              may not be available and are subject to change if prohibited or
              restricted by applicable law or regulation.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              Each Benefit may have additional terms or limitations, please read
              these separate terms and conditions carefully. You understand that
              any violation of any such rules and restrictions may result in
              your being denied access to the applicable product or services, in
              your forfeiting any monies paid for such product or service.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              You may be given an option to enroll premium subscription,
              business promotion, and advertisement through in-app messaging or
              other digital channels. Each Offer may include important
              corresponding terms, such as the type of Benefits included in a
              Subscription Plan, the cost of the Renewal Subscription and the
              billing interval. It is your responsibility to ensure that you
              have reviewed the Offer terms before you accept and purchase a
              Subscription, promotion, or advertisement.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              When you buy a Subscription, promotion, or advertisement Plan, you
              will be charged the Charge for your first Billing Cycle on the
              date of purchase, which activates the Benefits identified in the
              Offer. DoorPal app may elect to provide you with a free trial in
              which case you will be charged the Subscription Charge at the end
              of the trial period. If you do not wish to be charged, you must
              cancel the trial or promotional subscription before the end of the
              trial or promotional subscription. Eligibility for a free trial or
              promotional subscription is determined by DoorPal app at its sole
              discretion.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>
                EFFORTS AND SYSTEMS WILL BE PUT IN PLACE TO RENEW SUBSCIPTIONS
                AUTOMATICALLY, BUT YOUR SUBSCIPTION MAY NOT AUTOMATICALLY (AND
                WITHOUT PRIOR NOTICE TO THE EXTENT ALLOWED AT LAW), RENEW AT THE
                BEGINNING OF EACH BILLING CYCLE, THEREFORE IT IS YOUR SOLE
                RESPONSIBILITY TO RENEW IN A TIMELY MANNER TO NOT CAUSE
                DISRUPTIONS IN SERVICE. IT IS YOUR SOLE RESPONSIBILITY TO PAY
                THE DUE AMOUNT BEFORE THE BILLING CYCLE ENDS AND DoorPal APP
                reserves the right to terminate any unpaid Subscription Plan
                periods. Any paid Subscription Charges are non-refundable and
                non-transferable. PRICES, CHARGES, AND FEES MIGHT CHANGE WITHOUT
                PRIOR NOTICE.
              </strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              Where VAT (or equivalent taxes) is in force when you buy a Plan,
              the Charges shall be inclusive of VAT to the best of our ability.
              If any user is or becomes aware that they have tax obligations
              that were not fulfilled by us, they are fully responsible for
              fulfilling those obligations. Where there is a future change in
              the VAT rate, DoorPal app may at its discretion increase/decrease
              the Charge. Where VAT (or equivalent taxes) is not in force when
              you buy a Plan, the Charges shall be exclusive of VAT and if VAT
              (or equivalent) is later introduced, DoorPal app may add the
              applicable VAT to the Charges.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              You can cancel your Plan at any time prior to your next scheduled
              payment to avoid further charges and renewals. If you cancel your
              Subscription Plan, you will still have access to the Benefits
              until the end of the current Billing Cycle, and will not be
              refunded on a pro-rata basis for any cancellation initiated during
              a Billing Cycle. From time to time, DoorPal app may increase the
              Charge in which case you will be notified before your next Billing
              Cycle. If you do not wish to be charged the new Charge, you are
              required to cancel your subscription.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              DoorPal app makes no warranties or representations, either
              expressed or implied, and expressly disclaim all liability
              (including consequential damages) with respect to the
              availability, accuracy, timeliness, cost, quality or fitness of
              goods or services provided through any of its free services and
              paid plans.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              In addition, DoorPal app reserves the right to modify its free or
              paid Plans or any Benefit included in it, which changes will come
              into effect immediately, at your next Billing Cycle or at any
              other date determined by DoorPal app. DoorPal app may, among other
              things: (1) change the terms, including eligibility to or
              availability of Plans and the rules and terms that govern them;
              (2) increase or decrease the number of Benefits available; and/or
              (3) add or modify rules.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              DoorPal app reserves the right to cancel your free or paid Plan
              and/or your access to the platform and/or remove virtual doors or
              virtual DoorPals if you appear to be using the Plan or virtual
              doors or virtual DoorPals in a manner inconsistent with the terms
              or intent of the Plan Program or any portion thereof. This
              includes, but are not limited to: (1) violation of these terms and
              conditions; (2) violation of any national, state or local law or
              regulation in connection with the use of this Plan Program; (3)
              commission of fraud or abuse involving any portion of this service
              and its plans; or (4) action, in any other way, to the detriment
              of the DoorPal app or any of its stakeholders or (5) remove free
              virtual doors or virtual DoorPals that have been idol for more
              than a month; all as may be determined by DoorPal app in its sole
              discretion.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              Notwithstanding the foregoing, violations, fraud or abuse in
              relation to any DoorPal app service or usage is subject to
              appropriate administrative and/or legal action by appropriate
              governmental authorities and/or by DoorPal app including, without
              limitation, the cancellation of the account.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>Communications.</p>
            <p style={{ color: "#000", textAlign: "start" }}>
              By becoming a User, you agree to receive communications from us,
              including via e-mail, text message, calls, and push notifications.
              You agree that texts, calls or pre-recorded messages may be
              generated by automatic telephone dialing systems (where allowed by
              and in compliance with local law). Communications from DoorPal
              app. may include but are not limited to: operational
              communications concerning your User account or use of the DoorPal
              app Platform or Services, updates concerning new and existing
              features on the DoorPal app Platform, communications concerning
              promotions run by us or our third- party partners, and news
              concerning DoorPal app and industry developments. Standard text
              messaging charges applied by your cell phone carrier will apply to
              text messages we send. If you no longer wish to receive
              promotional or marketing-related communications by text message,
              please contact support@doorbellap.com and/or review the Privacy
              Policy for further information.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Charges, Payments, and Cancellations:&nbsp;</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              Any tax is additional. Fees are non-refundable, even for posts we
              remove, delay, omit, re-categorize, re-rank, or otherwise
              moderate. We may refuse any listing.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              As a User, you agree to pre-pay any amounts charged by our
              non-free plans for providing Services to you
              (&ldquo;Charges&rdquo;). Charges in general will be inclusive of
              applicable taxes where required by law, including any applicable
              VAT and/or sales tax to the best of our ability. However, users
              and customers should acknowledge and agree that DoorPal app might
              be subject to certain Taxes and Fees (including, but not limited
              to, assessments for universal service) that are not applicable to
              DoorPal app Services, might not be included in the charges.
              Accordingly, DoorPal app shall invoice the users or customers for
              Taxes and Fees associated with these additional initially
              un-included Charges.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              Charges paid by you are final and non-refundable. You do not
              retain the right to request lower Charges. All Charges are due
              prior to receiving the non-free DoorPal app services. If your
              primary Account payment method is determined to be expired,
              invalid or otherwise not able to be charged, you agree that
              DoorPal app may, use a secondary payment method in your Account,
              if available. You acknowledge and agree that Charges may be varied
              or revised at any time without your consent and that in certain
              geographical areas or at certain times Charges may increase
              substantially in places with or during times of high demand and
              you will be responsible for Charges incurred under your Account
              regardless of your awareness of such Charges, as applicable.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              No adjustment will be made, or credit or refund given, for usage
              that is less than the DoorPal app Commitment.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              You agree that DoorPal app may charge to Your credit card or other
              payment mechanism selected by You and approved by DoorPal app
              (&ldquo;Your Account&rdquo;) all amounts due and owing for the
              Services, including taxes and service fees, set up fees,
              subscription fees, or any other fee or charge associated with Your
              Account. DoorPal app may change prices at any time, including
              changing from a free service to a paid service and charging for
              Services that were previously offered free of charge; provided,
              however, that DoorPal app will provide you with prior notice and
              an opportunity to terminate Your Account if DoorPal app changes
              the price of a Service to which you are subscribed and will not
              charge you for a previously free Service unless you have been
              notified of the applicable fees and agreed to pay such fees. You
              agree that the email you provide is correct and that you are
              responsible for checking on it regularly and reply in less than 7
              days to avoid missing a charge change notification and that
              DoorPal is not obligated to wait from more than 1 week for you to
              prevent planned extra-charges. You agree that in the event DoorPal
              app is unable to collect the fees owed to DoorPal app for the
              Services through Your Account, DoorPal app may take any other
              steps it deems necessary to collect such fees from You and that
              You will be responsible for all costs and expenses incurred by
              DoorPal app in connection with such collection activity, including
              collection fees, court costs and attorneys&rsquo; fees. You
              further agree that DoorPal app may collect interest at the lesser
              of 1.5% per month or the highest amount permitted by law on any
              amounts not paid when due. You may cancel your subscription at any
              time. If you cancel, you will not be billed for any additional
              terms of service, and service will continue until the end of the
              current Subscription Term. If you cancel, you will not receive a
              refund for any service already paid for.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>
                Managed Listing, Door, location, point, place assignment and Map
                Posting
              </strong>
              . DoorPal app permits Customers to list on a searchable open list
              and to post details on a map for individuals, businesses,
              enterprises, event organizers and other users, and to manage any
              accounts that are subscribed to the DoorPal app. Customer may only
              associate to the DoorPal app Services post or listing that they
              own or are legally entitled to associate with for the use with the
              Services. Users fully responsible for and should refrain from
              assigning doors or location pins to private or public properties
              or businesses they don&rsquo;t own or lack the legal right of use
              such as a lease for a rented apartment or written consent by the
              rightful owner.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Customer&rsquo;s and All Users Obligations</strong>. Not
              put themselves, other users, their devices, or networks in
              harm&rsquo;s way, or to participate, facilitate, or encourage
              illegal activities, or to over or abuse the platform as described
              elsewhere. Also, see &ldquo;prohibited use&rdquo; elsewhere.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Reasonable Use and Right to Review</strong>. &nbsp;
              DoorPal app use is subject to reasonable use limitations.
              &nbsp;DoorPal app Plans are for normal and reasonable individual
              and business use; unreasonable use is prohibited. &nbsp;Use of
              DoorPal app may qualify as unreasonable if user (a) engages in
              individual or business activities that involve prolonged
              interrupted or continual, uninterrupted, or consistently excessive
              use of DoorPal app, (b) makes any misrepresentations to DoorPal
              app that materially affect volume or type of use of DoorPal app
              Services, (c) engages in fraudulent or illegal use of DoorPal app
              Services, including any activity that violates telemarketing laws
              or regulations, or (d) uses DoorPal app Services in any manner
              that harms DoorPal app functionality, network or facilities or
              interferes with the use of the service by other users or
              customers, or (e) use DoorPal app services in any manner that
              harms participating smart devices functions and their network
              resources . Use that is inconsistent with the types and levels of
              usage by typical individual and business users or customers on the
              same plan may be used as an indicator of abnormal or unreasonable
              use, including but not limited to abnormal usage lengths; abnormal
              usage frequency; abnormal usage duration; abnormal usage patterns
              that might indicate an attempt to evade reasonable use
              limitations. &nbsp;DoorPal app reserves the right to review users
              or customer use to determine if it is reasonable. In the event
              DoorPal app services determines that You may be engaging in
              unreasonable use, DoorPal app services will determine the
              appropriate remedy and will take action to remedy any unreasonable
              use, including, at its sole discretion, discussing the use with
              You, moving You to an appropriate DoorPal app Plan, terminating
              certain Hosts, and/or otherwise modifying, suspending or
              terminating Your DoorPal app services
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>
                NO HIGH RISK USE or DoorPal app usage in Emergencies (e.g., fire
                code, safety alert, others) Customer Obligations
              </strong>
              . &nbsp;Customer acknowledges and agrees that DoorPal app is not
              an emergency service provider, and if a customer uses it in that
              capacity, they bare the sole responsibility of this use. The
              Services are not designed or licensed for use in hazardous
              environments or situations requiring fail-safe controls, including
              without limitation operation of nuclear facilities, aircraft
              navigation/communication systems, air traffic control, and life
              support or weapons systems. The Services shall not be used for or
              in any HIGH RISK environment or situation. After the full
              satisfactory utilization of government approved fail-safe
              controls, DoorPal app might be used as an additional compassionate
              backup measure, with the understanding that this is undertaken at
              the sole full responsibility of the user, with the users
              acknowledgment that this is not a government approved use of
              DoorPal app (unless specified otherwise in print), and the
              understanding that DoorPal app might not deliver the desired
              outcome or might have a paradoxical undesirable effect. The
              customer also acknowledges that if they still intent to use
              DoorPal app for purposes such as an alarm or panic button it is
              their obligation to conduct regular at least weekly quality
              assurance drills.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Warranties</strong>. &nbsp;DoorPal app services do not
              offer any warranties and is not responsible for any participating
              Device warranties.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>
                NO WARRANTIES.YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE
                PROVIDED &ldquo;AS IS&rdquo; AND DoorPal APP, ITS AFFILIATES,
                SUPPLIERS AND RESELLERS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY
                KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY
                WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                NON-INFRINGEMENT. DoorPal APP, ITS AFFILIATES, SUPPLIERS AND
                RESELLERS MAKE NO WARRANTY OR REPRESENTATION REGARDING THE
                RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES,
                REGARDING THE ACCURACY OR RELIABILITY OF ANY INFORMATION
                OBTAINED THROUGH THE SERVICES OR THAT THE SERVICES WILL MEET ANY
                USER&rsquo;S REQUIREMENTS, OR BE UNINTERRUPTED, TIMELY, SECURE
                OR ERROR FREE. USE OF THE SERVICES IS AT YOUR SOLE RISK. ANY
                MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH
                THE USE OF THE SERVICES IS AT YOUR OWN DISCRETION AND RISK. YOU
                WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOU RESULTING FROM
                THE USE OF THE SERVICES. THE ENTIRE RISK ARISING OUT OF USE OR
                PERFORMANCE OF THE SERVICES REMAINS WITH YOU. DoorPal APP DOES
                NOT ASSUME ANY RESPONSIBILITY FOR RETENTION OF ANY USER
                INFORMATION OR COMMUNICATIONS BETWEEN USERS. DoorPal APP CANNOT
                GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF
                THE SERVICES. USE IT AT YOUR OWN RISK.
              </strong>
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Returns and Refunds</strong>. None are offered or
              supported by DoorPal app services.&nbsp;
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>TERMINATION</strong>. The DoorPal app contains information
              on how to terminate your Account. If you have purchased a Service
              for a specific term, such termination will be effective on the
              last day of the then-current term. Your Order Form may provide
              that a Renewal Term will begin automatically unless either party
              provides notice of termination at least thirty (30) days prior to
              the commencement of the next Renewal Term. If You fail to comply
              with any provision of this Agreement, DoorPal app may terminate
              this Agreement immediately and retain any fees previously paid by
              You. Except for the license to use DoorPal app. other ingredients
              of the &ldquo;terms of service&rdquo; shall survive any
              termination of this Agreement. Upon any termination of this
              Agreement, you must cease any further use of the Services. If at
              any time you are not happy with the Services, your sole remedy is
              to cease using the Services and follow this termination process.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>PROPRIETARY RIGHTS</strong>. DoorPal app and/or its
              suppliers, as applicable, retain ownership of all proprietary
              rights in the Services and in all trade names, trademarks, service
              marks, logos, and domain names (&ldquo;DoorPal app Marks&rdquo;)
              associated or displayed with the Services. You may not frame or
              utilize framing techniques to enclose any DoorPal app Marks, or
              other proprietary information (including images, text, page
              layout, or form) of DoorPal app without express written consent.
              You may not use any meta tags or any other &ldquo;hidden
              text&rdquo; utilizing DoorPal app Marks without DoorPal
              app&rsquo;s express written consent.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>COPYRIGHT</strong>. You may not post, modify, distribute,
              or reproduce in any way copyrighted material, trademarks, rights
              of publicity or other proprietary rights without obtaining the
              prior written consent of the owner of such proprietary rights.
              DoorPal app may deny access to the Services to any User who is
              alleged to infringe another party&rsquo;s copyright. Without
              limiting the foregoing, if You believe that Your copyright has
              been infringed, please notify DoorPal app thru
              support@doorbellap.com.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>EXPORT RESTRICTIONS.</strong> You acknowledge that the
              Services, or a portion thereof, are subject to the Export
              Administration Regulations, 15 C.F.R. Parts 730-774, of the United
              States and may be subject to other applicable country export
              control and trade sanctions laws (&ldquo;Export Control and
              Sanctions Laws&rdquo;). You and Your End Users may not access,
              use, export, re-export, divert, transfer or disclose any portion
              of the Services or any related technical information or materials,
              directly or indirectly, in violation of Export Control and
              Sanctions Laws. You represent and warrant that: (i) You and Your
              End Users (a) are not citizens of, or located within, a country or
              territory that is subject to U.S. trade sanctions or other
              significant trade restrictions (including without limitation Cuba,
              Iran, North Korea, and Syria) and that You and Your End Users will
              not access or use the Services, or export, re-export, divert, or
              transfer the Services, in or to such countries or territories; (b)
              are not persons, or owned 50% or more, individually or in the
              aggregate by persons, identified on the U.S. Department of the
              Treasury&rsquo;s Specially Designated Nationals and Blocked
              Persons List or Foreign Sanctions Evaders Lists; and (c) are not
              persons on the U.S. Department of Commerce&rsquo;s Denied Persons
              List, Entity List, or Unverified List, or U.S. Department of State
              proliferation-related lists; (ii) You and Your End Users will not
              put DoorPal app&rsquo;s Services to a Military End Use, as defined
              in 15 C.F.R. 744.21; (iii) no Content created or submitted by You
              or Your End Users is subject to any restriction on disclosure,
              transfer, download, export or re-export under the Export Control
              and Sanctions Laws; and (iv) You and Your End Users will not take
              any action that would constitute a violation of, or be penalized
              under, U.S. antiboycott laws administered by the U.S. Department
              of Commerce or the U.S. Department of the Treasury. You are solely
              responsible for complying with the Export Control and Sanctions
              Laws and monitoring them for any modifications.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>INJUNCTIVE RELIEF</strong>. You acknowledge that any use
              of the Services contrary to this Agreement, or any transfer,
              sublicensing, copying or disclosure of technical information or
              materials related to the Services, may cause irreparable injury to
              DoorPal app, its Affiliates, suppliers and any other party
              authorized by DoorPal app to resell, distribute, or promote the
              Services (&ldquo;Resellers&rdquo;), and under such circumstances
              DoorPal app, its Affiliates, suppliers and Resellers will be
              entitled to equitable relief, without posting bond or other
              security, including, but not limited to, preliminary and permanent
              injunctive relief.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              Disclaimers; Limitation of Liability; Indemnity
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>DISCLAIMER.</p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>
                THE DoorPal APP PLATFORM AND SERVICES ARE PROVIDED &ldquo;AS
                IS&rdquo; AND &ldquo;AS AVAILABLE.&rdquo; DoorPal APP DISCLAIMS
                ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR
                STATUTORY, NOT EXPRESSLY SET OUT IN THIS AGREEMENT, INCLUDING
                THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, DoorPal
                APP MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING
                THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR
                AVAILABILITY OF THE DoorPal APP PLATFORM, SERVICES OR ANY OTHER
                SERVICES REQUESTED THROUGH THE USE OF THE DoorPal APP PLATFORM,
                OR THAT THE DoorPal APP PLATFORM WILL BE UNINTERRUPTED OR
                ERROR-FREE. DoorPal APP AND ITS AFFILIATES DO NOT GUARANTEE THE
                QUALITY, SUITABILITY, SAFETY OR ABILITY OF CAPTAINS. YOU AGREE
                THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE DoorPal APP
                PLATFORM AND SERVICES, AND ANY SERVICE REQUESTED IN CONNECTION
                THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT
                PERMITTED UNDER APPLICABLE LAW.
              </strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              DoorPal app and its Affiliates are not responsible for the
              conduct, whether online or offline, of any User, participant,
              business, private place owner or inhabitant, public place
              inhabitant, Restaurant, mobile operator, or any other third party.
              You are encouraged to use a reasonable degree of sensibility and
              caution when interacting with other Users, participants, or any
              other third party.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              We are not required to procure insurance for, nor are we
              responsible for private belongings, such as the damage or the loss
              of private belongings including but not limited to damage or loss
              caused by: (i) theft or snatching (ii) opening a home or place to
              a unwelcomed stranger in any capacity; or (iii) Merchant User (or
              their authorized personnel) when providing a service or a product
              which you have looked for or accepted to engage with through the
              DoorPal app Platform. You are advised not to keep your home,
              office, children, and personal belongings unattended, including
              when you request a Merchant User to provide services in your
              household or other premises.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              By using the DoorPal app Platform and participating in the
              Services, you agree to accept such risks and agree that DoorPal
              app is not responsible for the acts or omissions of Users,
              participants, businesses, Restaurant, Merchant, mobile operators,
              or any other third party.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              You hereby acknowledge that DoorPal app is not acting as a broker
              in connection therewith, nor does it provide any guarantees,
              warranties, or any other assurances in connection therewith.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              DoorPal app and its Affiliates expressly disclaim any liability
              arising from the unauthorized use of your User account. Should you
              suspect that any unauthorized party may be using your User account
              or you suspect any other breach of security, you agree to notify
              us immediately.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              Location data provided by the DoorPal app Platform is for basic
              location purposes only and is not intended to be relied upon in
              situations where precise location information is needed or where
              erroneous, inaccurate or incomplete location data may lead to
              death, personal injury, property or environmental damage. Neither
              DoorPal app, nor its Affiliates nor any of its content providers,
              guarantees the availability, accuracy, completeness, reliability,
              or timeliness of location data tracked or displayed by the DoorPal
              app Platform. Any of your information, including geolocational
              data, you upload, provide, or post on the DoorPal app Platform may
              be accessible to DoorPal app, selected partners and relevant
              authorities.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              LIMITATION OF LIABILITY.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                WILL DoorPal APP OR ITS AFFILIATES, SUPPLIERS OR RESELLERS BE
                LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, EXEMPLARY OR
                CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION,
                DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION,
                LOSS OF BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS OR
                DAMAGE) ARISING OUT OF THE USE OF OR INABILITY TO USE THE
                SERVICES&nbsp;
              </strong>
              <strong>
                OR ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY USER OR
                OTHER THIRD PARTY PROVIDER
              </strong>
              <strong>
                &nbsp;OR THE PROVISION OF OR FAILURE TO PROVIDE TECHNICAL OR
                OTHER SUPPORT SERVICES, WHETHER ARISING IN TORT (INCLUDING
                NEGLIGENCE) CONTRACT OR ANY OTHER LEGAL THEORY, EVEN IF DoorPal
                APP, ITS AFFILIATES, SUPPLIERS OR RESELLERS HAVE BEEN ADVISED OF
                THE POSSIBILITY OF SUCH DAMAGES.&nbsp;
              </strong>
              <strong>
                YOU ACKNOWLEDGE THAT USERS MAY NOT BE WHO THEY CLAIM TO
                BE.&nbsp;
              </strong>
              <strong>
                IN ANY CASE, DoorPal APP&rsquo;S, ITS AFFILIATES&rsquo;,
                SUPPLIERS&rsquo; AND RESELLERS&rsquo; MAXIMUM CUMULATIVE
                LIABILITY AND YOUR EXCLUSIVE REMEDY FOR ANY CLAIMS ARISING OUT
                OF OR RELATED TO THIS AGREEMENT WILL BE LIMITED TO THE AMOUNT
                ACTUALLY PAID BY YOU FOR THE SERVICES (IF ANY) IN THE TWELVE
                (12) MONTHS PRECEDING THE EVENT OR CIRCUMSTANCES GIVING RISE TO
                SUCH CLAIMS. In states and jurisdictions that do not allow the
                exclusion or limitation of liability, you are not granted
                permission to use DoorPal app services.
              </strong>
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>INDEMNIFICATION</strong>.&nbsp;
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              You agree to indemnify, defend and hold harmless DoorPal app, its
              affiliates, officers, directors, employees, consultants, agents,
              suppliers and Resellers from any and all third party claims,
              liability, damages and/or costs (including, but not limited to,
              attorneys&rsquo; fees) arising from Your use of the Services, Your
              violation of this Agreement or the infringement or violation by
              You or any other user of Your account, of any intellectual
              property or other right of any person or entity or applicable law.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>LIQUIDATED DAMAGES.&nbsp;</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              You further agree that if you violate the terms of use, or you
              encourage, support, benefit from, or induce others to do so, you
              will be jointly and severally liable to us for liquidated damages
              as follows for:&nbsp;
            </p>
            <ul style={{ color: "#000", textAlign: "start" }}>
              <li>
                collecting/harvesting users&apos; information, including
                personal or identifying information - $1 per violation;&nbsp;
              </li>
              <li>
                publishing/misusing personal or identifying information of a
                third party in connection with your use of DoorPal App without
                that party&apos;s express written consent - $1,000 per
                violation;&nbsp;
              </li>
              <li>
                misrepresenting your identity or affiliation to anyone in
                connection with your use of DoorPal app- $1,000 per
                violation;&nbsp;
              </li>
              <li>
                posting or attempting to post Prohibited Content - $4 per
                violation;&nbsp;
              </li>
              <li>
                requesting, viewing, or accessing more than 100 listings on
                DoorPal App in any 24-hour period - $0.25 per listing during the
                24 hour period after the first 100 listings,&nbsp;
              </li>
              <li>
                sending an unauthorized/unsolicited email to an email address
                obtained from DoorPal App- $25 per violation;&nbsp;
              </li>
              <li>
                creating a misleading or unlawful DoorPal App account or
                buying/selling a DoorPal App account - $4 per violation;&nbsp;
              </li>
              <li>
                abusing or attempting to abuse DoorPal App&apos;s flagging or
                reporting processes - $1 per violation;&nbsp;
              </li>
              <li>
                distributing any software to facilitate violations of the any
                Section - $1,000 per violation;&nbsp;
              </li>
              <li>
                aggregating, displaying, framing, copying, duplicating,
                reproducing, making derivative works from, distributing,
                licensing, selling, or exploiting DoorPal App content for any
                purpose without our express written consent - $3,000 for each
                day you engage in such violations. You agree that these amounts
                are (1) a reasonable estimate of our damages (as actual damages
                are often difficult to calculate), (2) not a penalty, and (3)
                not otherwise limiting on our ability to recover under any legal
                theory or claim, including statutory damages and other equitable
                relief (e.g., for spam, we can elect between the above
                liquidated damages or statutory damages under the anti-spam
                statute). You further agree that repeated violations of the any
                section will irreparably harm and entitle us to injunctive or
                equitable relief, in addition to monetary damages
              </li>
            </ul>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              Dispute Resolution
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              Arbitration Process and Rules.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              Any dispute, conflict, claim or controversy arising out of or
              broadly in connection with or relating to the DoorPal app Platform
              or this Agreement, including those relating to its validity, its
              construction or its enforceability (any &ldquo;Dispute&rdquo;)
              shall be first mandatorily submitted to mediation proceedings
              under the International Chamber of Commerce Mediation Rules
              (&ldquo;ICC Mediation Rules&rdquo;), through arbitration pursuant
              to the provisions of Exhibit A. If such Dispute has not been
              settled within sixty (60) days after a request for mediation has
              been submitted under such ICC Mediation Rules, such Dispute can be
              referred to and shall be exclusively and finally resolved by
              arbitration under the Rules of Arbitration of the International
              Chamber of Commerce (&ldquo;ICC Arbitration Rules&rdquo;). The ICC
              Rules&apos; Emergency Arbitrator provisions are excluded. The
              Dispute shall be resolved by one (1) arbitrator to be appointed in
              accordance with the ICC Rules. The place of both mediation and
              arbitration shall be in the city in which the applicable Affiliate
              with which you have a Dispute has its registered office or where
              the owner&rsquo;s country of residence is. The language of the
              mediation and/or arbitration shall be English. The existence and
              content of the mediation and arbitration proceedings, including
              documents and briefs submitted by the parties, correspondence from
              and to the International Chamber of Commerce, correspondence from
              the mediator, and correspondence, orders and awards issued by the
              sole arbitrator, shall remain strictly confidential and shall not
              be disclosed to any third party without the express written
              consent from the other party unless: (i) the disclosure to the
              third party is reasonably required in the context of conducting
              the mediation or arbitration proceedings; and (ii) the third party
              agrees unconditionally in writing to be bound by the
              confidentiality obligation stipulated herein.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>WAIVER OF CLASS ACTION</strong>. Regardless of your
              location, you agree to resolve disputes only on an individual
              basis,{" "}
              <strong>
                The parties expressly waive any right to bring any action,
                lawsuit, or proceeding as a class or collective action, private
                attorney general action, or any other proceeding in which any
                party acts or proposes to act in a representative capacity
              </strong>
              .
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Exhibit A</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Binding Arbitration</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              This Exhibit A to the terms of service describes the further
              provisions which apply to the Binding Arbitration and Class Action
              Waiver.
            </p>
            <ul style={{ color: "#000", textAlign: "start" }}>
              <li>
                <strong>Disputes</strong>. A dispute is any controversy between
                You and DoorPal app concerning the Services, any software
                related to the Services, the price of the Services, your
                account, DoorPal app&rsquo;s advertising, marketing, or
                communications, Your purchase transaction or billing, or any
                term of this Agreement, under any legal theory including
                contract, warranty, tort, statute, or regulation, except
                disputes relating to the enforcement or validity of Your or
                DoorPal app&rsquo;s intellectual property rights. As part of the
                best-efforts process to resolve disputes, and prior to
                initiating arbitration proceedings, each party agrees to provide
                notice of the dispute to the other party, including a
                description of the dispute, what efforts have been made to
                resolve it, and what the disputing party is requesting as
                resolution, to support@doorbellap.com.
              </li>
              <li>
                <strong>Claims</strong>. DoorPal app is only obligated to honor
                claims in the courts of Saudi Arabia.
              </li>
              <li>
                <strong>Arbitration Procedure</strong>. Disputes not resolved
                pursuant to Section A be resolved through arbitration.&nbsp;
              </li>
              <li>
                <strong>Arbitration Fees</strong>. DoorPal app assumes no
                obligation to arbitration fees.
              </li>
              <li>
                <strong>Requirement to File Within One Year</strong>.
                Notwithstanding any other statute of limitations, a claim or
                dispute under this Agreement must be filed or noticed for
                arbitration within one year of when it could first be filed, or
                such claim will be permanently barred.
              </li>
            </ul>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>PRIVACY AND OTHER POLICIES</strong>. Use of the Services
              is also subject to DoorPal app&rsquo;s Privacy Statement, a link
              to which can be found by selecting &ldquo;Privacy and Legal
              Policies&rdquo; in the app. The Privacy Statement are incorporated
              into this Agreement by this reference. Additionally, You
              understand and agree that DoorPal app may contact You via e-mail
              or otherwise with information relevant to Your use of the
              Services, regardless of whether you have opted out of receiving
              marketing communications or notices.
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>MISCELLANEOUS</strong>
            </p>
            {/* <p style={{ color: "#000", textAlign: "start" }}>
              <br />
            </p> */}
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>Waiver and Severability</strong>.&nbsp;Failure by either
              Party to exercise any of its rights under, or to enforce any
              provision of, this Agreement will not be deemed a waiver or
              forfeiture of such rights or ability to enforce such provision. If
              any provision of this Agreement is held by a court of competent
              jurisdiction to be illegal, invalid or unenforceable, that
              provision will be amended to achieve as nearly as possible the
              same economic effect of the original provision and the remainder
              of this Agreement will remain in full force and effect. If any
              provision of this Agreement is held to be illegal, invalid or
              unenforceable, in whole or in part, under any law, such provision
              or part thereof shall to that extent be deemed not to form part of
              this Agreement but the legality, validity and enforceability of
              the other provisions in this Agreement shall not be affected. In
              that event, the parties shall replace the illegal, invalid or
              unenforceable provision or part thereof with a provision or part
              thereof that is legal, valid and enforceable and that has, to the
              greatest extent possible, a similar effect as the illegal, invalid
              or unenforceable provision or part thereof, given the contents and
              purpose of this Agreement. This Agreement constitutes the entire
              agreement and understanding of the parties with respect to its
              subject matter and replaces and supersedes all prior or
              contemporaneous agreements or undertakings regarding such subject
              matter.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              <strong>General Provisions</strong>.&nbsp;This Agreement embodies
              the entire understanding and agreement between the Parties
              respecting the subject matter of this Agreement and supersedes any
              and all prior understandings and agreements between the Parties
              respecting such subject matter, except that if You or Your company
              have executed a separate written agreement or you have signed an
              order form referencing a separate agreement governing your use of
              the Services, then such agreement shall control to the extent that
              any provision of this Agreement conflicts with the terms of such
              agreement. DoorPal app may elect to change or supplement the terms
              of this Agreement from time to time at its sole discretion.
              DoorPal app will exercise commercially reasonable business efforts
              to provide notice to You of any material changes to this
              Agreement. Within seven (7) business days of posting changes to
              this Agreement (or seven (7) business days from the date of
              notice, if such is provided), they will be binding on You. If You
              do not agree with the changes, You should discontinue using the
              Services. If You continue using the Services after such
              seven-business-day period, You will be deemed to have accepted the
              changes to the terms of this Agreement. In order to participate in
              certain Services, You may be notified that You are required to
              download software and/or agree to additional terms and conditions.
              Unless expressly set forth in such additional terms and
              conditions, those additional terms are hereby incorporated into
              this Agreement. This Agreement has been prepared in the English
              Language and such version shall be controlling in all respects and
              any non-English version of this Agreement is solely for
              accommodation purposes.&nbsp;&nbsp;You may not assign or transfer
              your rights under this Agreement in whole or in part without
              DoorPal app&rsquo;s prior written approval. You give your approval
              to DoorPal app for it to assign or transfer its rights and
              obligations under this Agreement in whole or in part, including
              to: (i) a subsidiary or affiliate; (ii) an acquirer of DoorPal
              app&rsquo;s equity, business or assets; or (iii) a successor by
              merger. No joint venture, partnership, employment or agency
              relationship exists between you, or DoorPal app or any user or
              participating business as a result of the contract between you and
              DoorPal app or use of the DoorPal app Platform.
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>Notice.</p>
            <p style={{ color: "#000", textAlign: "start" }}>
              DoorPal app may give notice by means of a general notice on the
              DoorPal app Platform, electronic mail to your email address in
              your Account, or by written communication sent to your address as
              set forth in your Account. You may give notice to DoorPal app by
              written communication to DoorPal app&apos;s email address at
              support@doorbellap.com.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ResumeNew;
