import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useMediaQuery } from "react-responsive";
import pdf from "../../Assets/../Assets/manual.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function About() {
  const [numPages, setNumPages] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [loading, setLoading] = useState(true);

  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log("Done", numPages);
    setNumPages(numPages);
    setLoading(false);
  };

  const onLoadProgress = ({ loaded, total }) => {
    console.log(
      loaded,
      total,
      (Number(loaded) / Number(6944395)) * 100,
      "Testing"
    );
    setLoadingProgress((Number(loaded) / Number(6944395)) * 100);
  };

  return (
    <Container fluid className="about-section">
      <div
        style={{ height: loading ? 600 : "100%" }}
        className="Example__container__document"
      >
        {loading && (
          <div style={loadingContainerStyle}>
            <div style={loadingCircleStyle}>
              Loading... {loadingProgress?.toFixed(2)}%
            </div>
          </div>
        )}

        <Document
          onLoadSuccess={onDocumentLoadSuccess}
          file={pdf}
          onLoadProgress={onLoadProgress}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={isTabletOrMobile ? 0.35 : 1}
            />
          ))}
        </Document>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          Having trouble viewing the file?{" "}
          <a href={pdf} download>
            Click here to download.
          </a>
        </div>
      </div>
      <div style={fabContainerStyle}>
        <div style={fabStyle}>
          <a href={pdf} download style={fabLinkStyle}>
            ⬇️
          </a>
        </div>
      </div>
    </Container>
  );
}

const loadingContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  textAlign: "center",
};

const loadingCircleStyle = {
  display: "flex",
  padding: "20px",
  borderRadius: "50%",
  backgroundColor: "#03A9F4",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  fontSize: "16px",
  color: "#FFF",
  height: "150px",
  width: "150px",
  justifyContent: "center",
  alignItems: "center",
};

const fabContainerStyle = {
  position: "fixed",
  bottom: "20px",
  right: "20px",
  textAlign: "center",
};

const fabStyle = {
  width: "56px",
  height: "56px",
  backgroundColor: "#007bff",
  color: "#fff",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
  cursor: "pointer",
};

const fabLinkStyle = {
  color: "#fff",
  textDecoration: "none",
  fontSize: "24px",
};

export default About;
