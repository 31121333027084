import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";

import { AiOutlineDownload } from "react-icons/ai";

function ResumeNew() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section">
        {/* <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row> */}

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            border: "1px solid #ccc",
          }}
          className="pdf-pages-container"
        >
          <div style={{ padding: 60 }}>
            <h1 style={{ color: "#03a9f4", fontWeight: "bold" }}>
              DoorPal app Privacy Policy
            </h1>
            <p style={{ color: "#000", textAlign: "center" }}>
              Effective Date:<strong> June 2024</strong>
            </p>
            <p style={{ color: "#000", textAlign: "start" }}>
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You. We use Your Personal data to provide and improve
              the Service. By using the Service, You agree to the collection and
              use of information in accordance with this Privacy Policy.
            </p>
            <div className="section">
              <h4
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Interpretation and Definitions
              </h4>

              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Interpretation
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>

              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Definitions
              </h5>

              <p style={{ color: "#000", textAlign: "start" }}>
                For the purposes of this Privacy Policy:
              </p>

              <div className="section">
                <ul>
                  <li style={{ color: "#000", textAlign: "start" }}>
                    Account means a unique account created for You to access our
                    Service or parts of our Service.
                  </li>
                  <li style={{ color: "#000", textAlign: "start" }}>
                    Affiliate means an entity that controls, is controlled by or
                    is under common control with a party, where "control" means
                    ownership of 50% or more of the shares, equity interest or
                    other securities entitled to vote for election of directors
                    or other managing authority.
                  </li>
                  <li style={{ color: "#000", textAlign: "start" }}>
                    Application refers to DoorPal, the software program provided
                    by the Company.
                  </li>
                  <li style={{ color: "#000", textAlign: "start" }}>
                    Company (referred to as either "the Company", "We", "Us" or
                    "Our" in this Agreement) refers to DoorPal.
                  </li>
                  <li style={{ color: "#000", textAlign: "start" }}>
                    Country refers to: Saudi Arabia
                  </li>
                  <li style={{ color: "#000", textAlign: "start" }}>
                    Device means any device that can access the Service such as
                    a computer, a cellphone or a digital tablet.
                  </li>
                  <li style={{ color: "#000", textAlign: "start" }}>
                    Personal Data is any information that relates to an
                    identified or identifiable individual.
                  </li>
                  <li style={{ color: "#000", textAlign: "start" }}>
                    Service refers to the Application.
                  </li>
                  <li style={{ color: "#000", textAlign: "start" }}>
                    Service Provider means any natural or legal person who
                    processes the data on behalf of the Company. It refers to
                    third-party companies or individuals employed by the Company
                    to facilitate the Service, to provide the Service on behalf
                    of the Company, to perform services related to the Service
                    or to assist the Company in analyzing how the Service is
                    used.
                  </li>
                  <li style={{ color: "#000", textAlign: "start" }}>
                    Third-party Social Media Service refers to any website or
                    any social network website through which a User can log in
                    or create an account to use the Service.
                  </li>
                  <li style={{ color: "#000", textAlign: "start" }}>
                    Usage Data refers to data collected automatically, either
                    generated by the use of the Service or from the Service
                    infrastructure itself (for example, the duration of a page
                    visit).
                  </li>

                  <li style={{ color: "#000", textAlign: "start" }}>
                    You means the individual accessing or using the Service, or
                    the company, or other legal entity on behalf of which such
                    individual is accessing or using the Service, as applicable.
                  </li>
                </ul>
              </div>
            </div>

            <div className="section">
              <h4
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Collecting and Using Your Personal Data
              </h4>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Types of Data Collected
              </h5>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Personal Data
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to:
              </p>
              <ul>
                <li style={{ color: "#000", textAlign: "start" }}>
                  Email address
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>Username</li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  Phone number
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>Address</li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  Usage Data
                </li>

                <li style={{ color: "#000", textAlign: "start" }}>
                  Crashlytics
                </li>
              </ul>

              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Crashlytics
              </h5>

              <p style={{ color: "#000", textAlign: "start" }}>
                We utilize Crashlytics, a crash reporting service provided by
                Google LLC, to collect information about app crashes and errors
                for the purpose of improving app stability and performance.
                Crashlytics may collect certain data from your device, such as
                device model, operating system version, and crash logs. This
                information is used solely for debugging and troubleshooting
                purposes and is not shared with third parties for any other
                purposes. By using our app, you consent to the collection and
                processing of crash data by Crashlytics in accordance with their
                Privacy Policy
                <a
                  style={{ textAlign: "start" }}
                  href="https://firebase.google.com/support/privacy"
                >
                  {"   "} https://firebase.google.com/support/privacy
                </a>
              </p>

              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Usage Data
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                Usage Data is collected automatically when using the Service.
                Usage Data may include information such as Your Device's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that You visit, the
                time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data. When You
                access the Service by or through a mobile device, We may collect
                certain information automatically, including, but not limited
                to, the type of mobile device You use, Your mobile device unique
                ID, the IP address of Your mobile device, Your mobile operating
                system, the type of mobile Internet browser You use, unique
                device identifiers and other diagnostic data. We may also
                collect information that Your browser sends whenever You visit
                our Service or when You access the Service by or through a
                mobile device.
              </p>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Information from Third-Party Social Media Services
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                The Company allows You to create an account and log in to use
                the Service through the following Third-party Social Media
                Services:
              </p>
              <ul>
                <li style={{ color: "#000", textAlign: "start" }}>Google</li>

                <li style={{ color: "#000", textAlign: "start" }}>
                  Apple (IOS only)
                </li>
              </ul>
              <p style={{ color: "#000", textAlign: "start" }}>
                If You decide to register through or otherwise grant us access
                to a Third-Party Social Media Service, We may collect Personal
                data that is already associated with Your Third-Party Social
                Media Service's account, such as Your name, Your email address,
                You may also have the option of sharing additional information
                with the Company through Your Third-Party Social Media Service's
                account. If You choose to provide such information and Personal
                Data, during registration or otherwise, You are giving the
                Company permission to use, share, and store it in a manner
                consistent with this Privacy Policy.
              </p>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Information Collected while Using the Application
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                While using Our Application, in order to provide features of Our
                Application, We may collect, with Your prior permission:
              </p>
              <ul>
                <li style={{ color: "#000", textAlign: "start" }}>
                  Information regarding your location
                </li>
              </ul>
              <p style={{ color: "#000", textAlign: "start" }}>
                We use this information to provide features of Our Service, to
                improve and customize Our Service. The information may be
                uploaded to the Company's servers and/or a Service Provider's
                server or it may be simply stored on Your device. You can enable
                or disable access to this information at any time, through Your
                Device settings.
              </p>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Use of Your Personal Data
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                The Company may use Personal Data for the following purposes:
              </p>
              <ul>
                <li style={{ color: "#000", textAlign: "start" }}>
                  To provide and maintain our Service, including to monitor the
                  usage of our Service.
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  To manage Your Account: to manage Your registration as a user
                  of the Service. The Personal Data You provide can give You
                  access to different functionalities of the Service that are
                  available to You as a registered user.
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  For the performance of a contract: the development, compliance
                  and undertaking of the purchase contract for the products,
                  items or services You have purchased or of any other contract
                  with Us through the Service.
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  To contact You: To contact You by email, telephone calls, SMS,
                  or other equivalent forms of electronic communication, such as
                  a mobile application's push notifications regarding updates or
                  informative communications related to the functionalities,
                  products or contracted services, including the security
                  updates, when necessary or reasonable for their
                  implementation.
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  To provide You with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  To manage Your requests: To attend and manage Your requests to
                  Us.
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  For business transfers: We may use Your information to
                  evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of Our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  Personal Data held by Us about our Service users is among the
                  assets transferred.
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  For other purposes: We may use Your information for other
                  purposes, such as data analysis, identifying usage trends,
                  determining the effectiveness of our promotional campaigns and
                  to evaluate and improve our Service, products, services,
                  marketing and your experience.
                </li>
              </ul>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Retention of Your Personal Data
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </p>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Transfer of Your Personal Data
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                Your information, including Personal Data, is processed at the
                Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction. Your consent to
                this Privacy Policy followed by Your submission of such
                information represents Your agreement to that transfer. The
                Company will take all steps reasonably necessary to ensure that
                Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </p>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Delete Your Personal Data
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                You have the right to delete or request that We assist in
                deleting the Personal Data that We have collected about You. Our
                Service may give You the ability to delete certain information
                about You from within the Service. You may update, amend, or
                delete Your information at any time by signing in to Your
                Account, if you have one, and visiting the account settings
                section that allows you to manage Your personal information. You
                may also contact Us to request access to, correct, or delete any
                personal information that You have provided to Us. Please note,
                however, that We may need to retain certain information when we
                have a legal obligation or lawful basis to do so.{" "}
              </p>
              <h4
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Disclosure of Your Personal Data
              </h4>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Business Transactions
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Law enforcement
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </p>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Other legal requirements
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
              </p>
              <ul>
                <li style={{ color: "#000", textAlign: "start" }}>
                  Comply with a legal obligation
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  Protect and defend the rights or property of the Company
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  Protect the personal safety of Users of the Service or the
                  public
                </li>
                <li style={{ color: "#000", textAlign: "start" }}>
                  Protect against legal liability
                </li>
              </ul>
              <h5
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Security of Your Personal Data
              </h5>
              <p style={{ color: "#000", textAlign: "start" }}>
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
              </p>
              <h4
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Children's Privacy
              </h4>
              <p style={{ color: "#000", textAlign: "start" }}>
                Our Service does not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                anyone under the age of 13. If You are a parent or guardian and
                You are aware that Your child has provided Us with Personal
                Data, please contact Us. If We become aware that We have
                collected Personal Data from anyone under the age of 13 without
                verification of parental consent, We take steps to remove that
                information from Our servers. If We need to rely on consent as a
                legal basis for processing Your information and Your country
                requires consent from a parent, We may require Your parent's
                consent before We collect and use that information.
              </p>
              <h4
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Links to Other Websites
              </h4>
              <p style={{ color: "#000", textAlign: "start" }}>
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party's site. We strongly advise You to
                review the Privacy Policy of every site You visit. We have no
                control over and assume no responsibility for the content,
                privacy policies or practices of any third party sites or
                services.
              </p>
              <h4
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Changes to this Privacy Policy
              </h4>
              <p style={{ color: "#000", textAlign: "start" }}>
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page. We will let You know via email and/or a prominent
                notice on Our Service, prior to the change becoming effective
                and update the "Last updated" date at the top of this Privacy
                Policy. You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.
              </p>
              <h4
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Contact Us
              </h4>
              <p style={{ color: "#000", textAlign: "start" }}>
                If you have any questions about this Privacy Policy, You can
                contact us:
              </p>
              <ul>
                <li style={{ color: "#000", textAlign: "start" }}>
                  By email: support@doorbellap.com
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ResumeNew;
