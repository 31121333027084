import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "./sliderstyle.css";

function Slider({ slides, isMobile, PlayStore, AppStore }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animate, setAnimate] = useState(true); // Set initial animation to true

  useEffect(() => {
    // Trigger animation reset and slide changes
    const interval = setInterval(() => {
      setAnimate(false); // Reset animations

      setTimeout(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length); // Update slide
        setAnimate(true); // Trigger animations for new slide
      }, 500); // Time for fade-out/reset animations
    }, 5000); // Slide interval

    return () => clearInterval(interval); // Cleanup interval
  }, [slides.length]);

  return (
    <div className="slider-section">
      <Row>
        {/* Text Section */}
        <Col md={6} className="slider-text" style={{ marginTop: isMobile ? "" : "14%" }}>
          {/* Title */}
          <h1 className={`slider-title ${animate ? "text-animate" : ""}`}>
            {slides[currentSlide].title}
          </h1>

          {/* Description */}
          <div className={`slider-description ${animate ? "text-animate" : ""}`}>
            {slides[currentSlide].text.map((line, index) => (
              <span key={index} className="slider-line">
                <strong>{line}</strong>
              </span>
            ))}
          </div>

          {/* Buttons */}
          <div className={`buttons-container ${animate ? "buttons-animate" : ""}`}>
            <img
              src={PlayStore}
              alt="Android App"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.doorpalandroid",
                  "_blank"
                )
              }
              className="store-button"
            />
            <img
              src={AppStore}
              alt="Apple App"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/pk/app/door-pal/id6479244948",
                  "_blank"
                )
              }
              className="store-button"
            />
          </div>
        </Col>

        {/* Image Section */}
        <Col md={6} className="slider-image">
          <img
            src={slides[currentSlide].image}
            alt="slider"
            className={`img-fluid ${animate ? "img-fade-in" : ""}`}
            style={{
              objectFit: "contain",
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Slider;
