import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import "./Stick.css";
import facebook from "../../Assets/facebook.png";
import youtube from "../../Assets/youtube.png";
import twitter from "../../Assets/twitter.png";

const StickySocialIcons = () => {
  return (
    <div className="sticky-social-icons">
      <a
        target="_blank"
        href="https://www.facebook.com/profile.php?id=100075547720779&mibextid=ZbWKwL"
      >
        <img src={facebook} className="social-icon" />
      </a>
      <a
        target="_blank"
        href="https://twitter.com/doorbellboost?t=1eiSb5Zi7QsB5Y1A2icGFg&s=09"
      >
        <img src={twitter} className="social-icon" />
      </a>
      <a target="_blank" href="https://youtube.com/@doorbell9544">
        <img src={youtube} className="social-icon" />
      </a>
    </div>
  );
};

export default StickySocialIcons;
