import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiVideo, BiHelpCircle } from "react-icons/bi";

import { BsGithub } from "react-icons/bs";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      {props.title === "Help" ? (
        <Card.Img
          variant="top"
          src={props.imgPath}
          alt="card-img"
          style={{ height: 230, width: 230, alignSelf: "center" }}
        />
      ) : (
        <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      )}

      <Card.Body>
        <Card.Title style={{ color: "#000" }}>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify", color: "#000" }}>
          {props.description}
        </Card.Text>

        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        {props.title === "Help" ? (
          <Button
            onClick={props.onClick}
            variant=" "
            style={{
              marginLeft: "10px",
              background: "#03a9f4",
              marginTop: 40,
              color: "#ffffff",
            }}
          >
            <BiHelpCircle /> &nbsp;
            {"Help"}
          </Button>
        ) : (
          <Button
            onClick={props.onClick}
            variant=" "
            style={{
              marginLeft: "10px",
              background: "#03a9f4",
              color: "#ffffff",
            }}
          >
            <BiVideo /> &nbsp;
            {"Watch Video"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
