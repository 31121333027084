import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import one from "../../Assets/one.png";
import two from "../../Assets/two.png";
import three from "../../Assets/three.png";
import four from "../../Assets/four.png";
import five from "../../Assets/five.png";
import six from "../../Assets/linking.png";
import seven from "../../Assets/pranks.png";
import help from "../../Assets/helpforwebsite.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import VideoModal from "../VideoModal/VideoModal";

function Projects() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  // const videoUrl = require("../../Assets/Videos/qrcodes.mp4");

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          How can we <strong className="purple">Help </strong>
        </h1>
        <p style={{ color: "#000" }}>Here are a few video tutorials.</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              onClick={() => {
                setVideoUrl("https://www.youtube.com/watch?v=ElLeV92Tm2g");
                openModal();
              }}
              imgPath={one}
              isBlog={false}
              title="Generate QR Codes"
              description={`We take your visibility and availability seriously, therefore, we created not one but five ways that your visitor can find and ring you. The 1st is searching for you on a "list", the 2nd is a "map" search, the 3rd is thru a "shared link", the 4th is tapping an "NFC tag" on your door (Coming Soon), or fifthly by scanning a QR code. Learn more about generating a QR code in the video.`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              onClick={() => {
                setVideoUrl("https://www.youtube.com/watch?v=ah4HpiuV_lE");
                openModal();
              }}
              imgPath={two}
              isBlog={false}
              title="Door Settings"
              description={`People's needs are diverse, and we took that to heart by designing DoorPal to put you in the driver's seat, where you can customize your virtual door in innumerable ways. Learn more about it in the video.`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              onClick={() => {
                setVideoUrl("https://www.youtube.com/watch?v=EfaKYtHaBaU");
                openModal();
              }}
              imgPath={three}
              isBlog={false}
              title="CoHost Management"
              description={`Naturally when you create a virtual door and DoorPal, you might want others at home or work to be able to answer it too. Family, friends, and coworkers can easily be accounted for. See how in the video.`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              onClick={() => {
                setVideoUrl("https://www.youtube.com/watch?v=ThbyW5DkukY");
                openModal();
              }}
              imgPath={four}
              isBlog={false}
              title="Door Cloning"
              description={`Trust us, creating your first door is easy. Creating more doors can even be easier thru the cloning pathway. So if you are dreading the idea of creating multiple virtual doors and DoorPals we tried to make it easier for you. Check out the video.`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              onClick={() => {
                setVideoUrl("https://www.youtube.com/watch?v=vXN4FrjJca8");
                openModal();
              }}
              imgPath={five}
              isBlog={false}
              title="Appointments"
              description={`Life can be disorganized and chaotic, so it stands to reason that adding some organization thru an appointment system  may help both host and guests manage their time better and by extension add order to their life and work. We hope the video encourages you to try it.`}

              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              onClick={() => {
                setVideoUrl("https://youtu.be/WQYNqTZtilA");
                openModal();
              }}
              imgPath={six}
              isBlog={false}
              title="Door Linking"
              description={`Your convenience is important to us which is why making a change to multiple doors is a task that we anticipated and proactively made easier. So instead of repeating the change for each door individually allow the link function to do most of the work for you. See how we have your back in the video.`}

              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              onClick={() => {
                setVideoUrl("https://www.youtube.com/watch?v=If9sDnQs-Co");
                openModal();
              }}
              imgPath={seven}
              isBlog={false}
              title="Pranks"
              description={`Peace of mind is never guaranteed but we are not helpless in this regards when we take proper measures. Abuse of your virtual door and DoorPal can be mitigated to a great degree with many in-app tools that make abuse more difficult. See more in the video.`}

              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              onClick={() => {
                navigate("/help");
              }}
              imgPath={help}
              isBlog={false}
              title="Help"
              description={`For more how to use information , visit the "User Operating Guide Page.`}

              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>
          <Col md={4} className="project-card"></Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here
            />
          </Col> */}
        </Row>
      </Container>
      {showModal && <VideoModal videoUrl={videoUrl} onClose={closeModal} />}
    </Container>
  );
}

export default Projects;
