import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FAQ from "./FAQs";
import laptopImg from "../../Assets/about.png";
export default function FAQPage() {
  const [faqs, setFaqs] = useState([
    {
      question: "What need does the application fulfill?",
      answer: `The most obvious benefit is that the DoorPal platform can make us more or completely independent of physical Doorbells by providing a virtual alternative. We are confident that some users will embrace technology over tradition and question why have a physical home or office Doorbell at all and save the money that goes into having them towards something else. 
(FYI did you know that: The average Doorbell installation cost is 290$. But it doesn’t stop there, the annual operational cost is 3.5$ as a global average but might be much higher depending on where you live. For example, it is estimated to cost annually 4.18$ in the United States, 6.02$ in Japan, and 8.7$ in Germany)


But what may not be so obvious is that the DoorPal application will free us to move about (such as stepping outside our homes, away from our offices, or even be on the other side of the globe) without fear of missing a visitor at our doorsteps. 
(FYI did you know that: A typical Doorbell is 70 dB loud and in perfect conditions that can be heard kilometers away, but as we all know we never have perfect conditions, and therefore, the dependable operational range of a physical Doorbell has a range limit that is seldom more than 50 meters, which means you can miss a visitor at your doorstep if you wander too far)

With humanity adopting more social etiquettes and responsibilities to limit the spread of infectious diseases, your visitors might also appreciate that they don’t need to touch a Doorbell button that gets touched by other people. 

(FYI did you know: Corona virus lives on surfaces such as Doorbell buttons. How long the virus survives on a given surface varies from hours to days, depending on numerous factors. So yes, you can become infected with the virus after touching a contaminated surface. This is what scientist call fomite transmission. C.D.C. releases have indicated that indirect contact from a contaminated surface is a potential risk for catching Covid-19.)



The list of the DoorPal application advantages goes on, with a little imagination the application can open many new, creative and exciting off label uses. `,
      open: true,
    },
    {
      question: "What does the application do?",
      answer: `The DoorPal application enables hosts to have a mobile smart device alternative (or backup) to physical Doorbells used for homes, offices, private and public places. 

      This is accomplished simply by selecting the geo-location of the door on the application then telling the application your desired range and hours of operation. When a visitor is within the preset range and hours of operation, the application allows them to notify the host that they have arrived without using the physical Doorbell. Messaging, audio-video conferencing, and recording are also available to enhance experience, functionality and security.
      
      In the spirit of providing more options and greater flexibility for users, the application also allows users to generate a unique door QR code (or unique door link) that can be posted on the door or shared electronically for visitors to use. Scanning the door QR code (or clicking on the door link) is an alternative way a visitor can unlock the application to ring or notify the host of their arrival.`,
      open: false,
    },
    {
      question: "Why is it unique?",
      answer: `One way the DoorPal application is unique in that it does not require you to purchase any hardware such as Doorbells, electronics, intercoms, or cameras. This is a win-win situation, where the user can elect to save significant money by not having these items in the first place and while doing so also help the environment by reducing the demand for products that necessarily leave a carbon footprint when they are fabricated and transported. 
      (FYI did you know that in some estimations physical Doorbells indirectly add approximately 9.74-15.4 kg of CO2 to the environment annually. So, this means in addition to the potential savings of hundreds of dollars explained above, there is also potential to diminish ones carbon footprint significantly and be more Earth friendly)
      
      A second way it is unique is that you do not need to mount or install devices, need to extent wires and cables, or have construction work on your home or office. That labor will have to come out of your own time, or you might need to pay someone else for their labor. It also goes without saying what choice is kinder to the environment. 
      
      A third way it is unique is that the application in its own little way makes its users less likely to spread communicable diseases such as COVID because they don’t need to press the potentially contaminated surface of a DoorPal button that others have certainly touched. 
      
      Another way it is unique is that it liberates the user to move freely without fear of missing a visitor.
      
      As you can picture by now the application is unique in many other ways that are not mentioned. With a little imagination the application can open many new, creative and exciting off label uses. `,
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Know Who <strong className="purple">We Are</strong>
            </h1>

            <div className="faqs">
              {faqs.map((faq, index) => (
                <FAQ
                  faq={faq}
                  index={index}
                  key={index}
                  toggleFAQ={toggleFAQ}
                />
              ))}
            </div>
            <p style={{ color: "#000" }}>
              Generally speaking, the DoorPal platform, is the first (but we
              hope not the last) manifestation of an initiative to neutrally
              augment communities, enhance societies, empower people, and
              positvely boost the life we live, thru digital technologies.
              Specifically, this application promises to provide an innovative
              solution that should positively impact your convenience, freedom
              of movement, visibility, accessibility, availability, help the
              environment, improve your security, and likely save you money. Our
              team of experienced professionals is dedicated to delivering the
              highest quality products and services to our customers. We are
              constantly pushing ourselves to make our app accessibile and easy
              to use for everyone. In essence, what we are about can be stated
              as: “Good things happen when people are brought closer together”
              Please honor us by letting DoorPal be your first impression.
            </p>
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "10px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>

          <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
            <strong className="purple">Open-source code and credits:</strong>
          </h1>
          <p style={{ color: "#000" }}>
            “Sound effects obtained from https://www.zapsplat.com“
          </p>
          <p style={{ color: "#000" }}>
            Images and photos from flaticon, rawpixel.com, and others on
            Freepik, Pixabay, and Pexels.
          </p>
        </Row>
      </Container>
    </Container>
  );
}
