import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/onboardingscreenbackground.png";
import PlayStore from "../../Assets/playstoredownload.png";
import AppStore from "../../Assets/appstoredownload.png";
import { useMediaQuery } from 'react-responsive';
import Slider from './slider/slider'
import {
  FaBell,
  FaCalendarAlt,
  FaUserFriends,
  FaQrcode,
  FaTruck,
  FaMobileAlt,
} from "react-icons/fa";

import Image1 from "../../Assets/s1-2.PNG";
import Image2 from "../../Assets/s2-3.PNG";
import Image3 from "../../Assets/s3-2.png";
import Image4 from "../../Assets/s4.PNG";
import honeycombBackground from "../../Assets/honeycomb5.png"
import "./Home.css";
function Home() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const slides = [
    {
      title: "Not your ordinary doorbell",
      text: [
        // "Get instant alerts when someone visits",
        // "Manage your appointments",
        // "Invite your friends for an event or schedule their visits",
      `Unmatched setup flexibility where you can own a doorbell anywhere from anywhere! 
       Works anywhere even places that are impossible or impractical for physical doorbells! 
       Includes an appointment system & log history book.`,
      ],
      image: Image1,
    },
    {
      title:
        "Co-manage your doorbell so you never have to worry about missing visitors and deliveries",
      text: [],
      image: Image2,
    },
    {
      title:
        "Attend to your visitors from the comfort of your seat—just a QR code scan to ring your virtual doorbell!",
      text: [],
      image: Image3,
    },
    {
      title:"How it works",
      text: [ "Instead of just one button on a traditional doorbell, DoorPal offers multiple digital options for hosts and visitors to connect. When someone arrives, they can ring the bell using a QR code, a shareable link, or an in-app search."],
      image: Image4,
    },
  ];
  
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fade, setFade] = useState(true);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        setFade(true);
      }, 1000); // Duration of dark transition
    }, 5000); // Interval for slide change
  
    return () => clearInterval(interval);
  }, [slides.length]);
  
  return (
    <Container fluid className="about-section">
    {/* Slider Section */}
    <div style={{ backgroundImage: `url(${honeycombBackground})` }}>
      <Slider slides={slides} isMobile={isMobile} PlayStore={PlayStore} AppStore={AppStore} />
    </div>

      {/* Feature Section */}
      <div className="features-section py-14 bg-gray-100 mt-5">
        <h1 className="heading-name text-center mb-12">
          <strong className="main-name text-4xl font-extrabold text-gray-800">
            DoorPal Features
          </strong>
        </h1>
        <div className="container mx-auto px-6">
          <Row className="g-5">
            {/* Feature Card 1 */}
            <Col md={4} className="d-flex justify-content-center">
              <div className="bg-white border border-blue-200 border rounded-4 shadow-lg p-2 text-center transition duration-300 ease-in-out transform hover:bg-gray-200 hover:border-black hover:shadow-2xl hover:scale-105">
                <div className="icon-circle bg-blue-50 rounded-full mb-6 p-5 mx-auto">
                  <FaBell className="text-blue-600 text-5xl" size={40} />
                </div>
                <h3 className="text-2xl font-semibold text-blue-700 mb-3">
                  Customized availability
                </h3>
                <p className="text-gray-500 text-lg">
                  DoorPal allows hosts to specify availability hours. Visitors
                  outside those hours can leave a message but won't be able to
                  disturb the hosts by ringing the bell.
                </p>
              </div>
            </Col>

            {/* Feature Card 2 */}
            <Col md={4} className="d-flex justify-content-center">
              <div className="bg-white border border-red-200 rounded-4 shadow-lg p-2 text-center transition duration-300 ease-in-out transform hover:bg-gray-200 hover:border-black hover:shadow-2xl hover:scale-105">
                <div className="icon-circle bg-red-50 rounded-full mb-6 p-5 mx-auto">
                  <FaTruck className="text-red-600 text-5xl" size={40} />
                </div>
                <h3 className="text-2xl font-semibold text-red-700 mb-3">
                  Emergency alarm QR code
                </h3>
                <p className="text-gray-500 text-lg">
                  Although DoorPal is not a failsafe system, you can use DoorPal
                  as an additional layer of danger alerting or security to your
                  pre-existing failsafe setups in your house, building,
                  neighborhood, or community.
                </p>
              </div>
            </Col>

            {/* Feature Card 3 */}
            <Col md={4} className="d-flex justify-content-center">
              <div className="bg-white border border-purple-200 rounded-4 shadow-lg p-2 text-center transition duration-300 ease-in-out transform hover:bg-gray-200 hover:border-black hover:shadow-2xl hover:scale-105">
                <div className="icon-circle bg-purple-50 rounded-full mb-6 p-5 mx-auto">
                  <FaUserFriends
                    className="text-purple-600 text-5xl"
                    size={40}
                  />
                </div>
                <h3 className="text-2xl font-semibold text-purple-700 mb-3">
                  Family, friends, or others can also be notified
                </h3>
                <p className="text-gray-500 text-lg">
                  It won't be a doorbell if you alone heard it. You can assign
                  others as cohosts to also receive notification, answer on your
                  behalf, & manage your virtual doorbell. As the situation
                  requires that cohost can be family, friend, coworker, real
                  estate or sales agent, group or community member.
                </p>
              </div>
            </Col>

            {/* Feature Card 4 */}
            <Col md={4} className="d-flex justify-content-center">
              <div className="bg-white border border-yellow-200 rounded-4 shadow-lg p-2 text-center transition duration-300 ease-in-out transform hover:bg-gray-200 hover:border-black hover:shadow-2xl hover:scale-105">
                <div className="icon-circle bg-yellow-50 rounded-full mb-6 p-5 mx-auto">
                  <FaQrcode className="text-yellow-600 text-5xl" size={40} />
                </div>
                <h3 className="text-2xl font-semibold text-yellow-700 mb-3">
                  Advanced QR code capabilities
                </h3>
                <p className="text-gray-500 text-lg">
                  Use DoorPal to get a location specific & unique QR code for
                  each doorbell you create. For privacy encryption is applied
                  and you can choose for it to declare or conceal the location.
                </p>
              </div>
            </Col>

            {/* Feature Card 5 */}
            <Col md={4} className="d-flex justify-content-center">
              <div className="bg-white border border-green-200 rounded-4 shadow-lg p-2 text-center transition duration-300 ease-in-out transform hover:bg-gray-200 hover:border-black hover:shadow-2xl hover:scale-105">
                <div className="icon-circle bg-green-50 rounded-full mb-6 p-5 mx-auto">
                  <FaCalendarAlt
                    className="text-green-600 text-5xl"
                    size={40}
                  />
                </div>
                <h3 className="text-2xl font-semibold text-green-700 mb-3">
                  Managed appointments
                </h3>
                <p className="text-gray-500 text-lg">
                  DoorPal includes a built-in appointment management system,
                  allowing users to schedule visits and manage time effectively.
                </p>
              </div>
            </Col>

            {/* Feature Card 6 */}
            <Col md={4} className="d-flex justify-content-center">
  <div className="bg-white border border-indigo-200 rounded-4 shadow-lg p-4 text-center transition duration-300 ease-in-out transform hover:bg-gray-200 hover:border-black hover:shadow-2xl hover:scale-105">
    <div className="icon-circle bg-indigo-50 rounded-full mb-6 p-5 mx-auto">
      <FaMobileAlt className="text-indigo-600 text-5xl" size={40} />
    </div>
    <h3 className="text-2xl font-semibold text-indigo-700 mb-3">
      Advanced features for businesses
    </h3>
    <p className="text-gray-500 text-lg mb-3">
      Some features are designed for those who need multiple doorbells:
    </p>
    <ul className="text-gray-500 text-lg list-disc list-outside pl-5 space-y-2 text-left">
      <li>Faster doorbell creation with cloning</li>
      <li>Link doorbells to sync any changes</li>
      <li>Track activity and performance indicators for businesses</li>
    </ul>
  </div>
</Col>


          </Row>
        </div>
      </div>

      <div className="home-content">
        <Row>
          <Col md={7} className="home-header">
            <h1 style={{ paddingBottom: 15 }} className="heading">
              Hi There!{" "}
              <span className="wave" role="img" aria-labelledby="wave">
                👋🏻
              </span>
            </h1>
            <h1 style={{ paddingBottom: 15 }} className="heading2">
              Getting a smart Doorbell has never been easier or cheaper
            </h1>
            <h1
              className="heading-name"
              onClick={() => {
                console.log("Clicked!");
              }}
            >
              <strong className="main-name">Download the DoorPal App</strong>
            </h1>
            <div
              className="heading-name"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={PlayStore}
                alt="Android App"
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.doorpalandroid",
                    "_blank"
                  );
                }}
                style={{
                  width: "30%", // Use percentage for responsiveness
                  maxWidth: "200px", // Optional max width
                  height: "auto", // Maintain aspect ratio
                  marginRight: 15,
                  cursor: "pointer",
                }}
              />
              <img
                src={AppStore}
                alt="Apple App"
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/pk/app/door-pal/id6479244948",
                    "_blank"
                  );
                }}
                style={{
                  width: "30%", // Use percentage for responsiveness
                  maxWidth: "200px", // Optional max width
                  height: "auto", // Maintain aspect ratio
                  marginRight: 15,
                  cursor: "pointer",
                }}
              />
            </div>
          </Col>
          <Col md={5} className="about-img">
            <img
              src={homeLogo}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "450px", width: "100%", height: "auto" }} // Make it fully responsive
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Home;
