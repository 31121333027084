import React from "react";
import ReactPlayer from "react-player";
import "./VideoModal.css";

const VideoModal = ({ videoUrl, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* <button className="close-button" onClick={onClose}>
          <span>&times;</span>
        </button> */}
        <ReactPlayer
          className="video-player"
          url={videoUrl}
          playing
          controls
          width="100%"
          // height="100%"
          height="500px"
        />
      </div>
    </div>
  );
};

export default VideoModal;
